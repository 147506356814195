import { useRef, useState } from "react";
import { bookLanguage, bookNames, chapterCounts } from "../../bibleMeta";
import { Button, Grid, Stack, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  RemoveCircle,
} from "@material-ui/icons";
import { Dialog, DialogContent } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  scrollableContainer: {
    width: "100%",
    overflow: "auto",
    scrollbarWidth: 0.01, // For Firefox
    "&::-webkit-scrollbar": {
      width: 0.01, // Adjust the width as needed
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent", // Hide the scrollbar thumb
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent", // Hide the scrollbar track
    },
  },
  blinking: {
    backgroundColor: "#f2f5ff",
    /* color: #fff; */
    animation: "blinkingBackground 1.5s infinite",
  },
}));
export default function BiblePageOptions({
  bookNo,
  changeBook,
  chapterNo,
  changeChapter,
  verseNo,
  changeVerse,
  verseCount,
  globalVersions,
  changeVersions,
  primaryVersion,
  setPrimaryVersion,
  setVerseViewOpen,
  // previousBookNo,
  // previousChapterNo,
  // previousVerseNo,
  latTenINLocalStorage,
  lastTen,
  setLastTen,
  handleToggle,
  toggles,
  setMobileCommentry,
  setMobileViewSearch,
}) {
  const classes = useStyles();
  const [versions, setVersions] = useState(globalVersions);
  const [isTabshidden, setisTabshidden] = useState(true);

  const [tabValue, setTabValue] = useState(0);
  const [bookDialogAnchorEl, setBookDialogAnchorEl] = useState(null);
  const [verseLanguageDialog, setVerseLanguageDialog] = useState(false);

  const handleDialogBookChange = (value) => {
    changeBook(value);
    setTabValue(1);
  };

  const handleDialogChapterChange = (value) => {
    changeChapter(value);
    setTabValue(2);
  };

  const handleDialogVerseChange = (value) => {
    changeVerse(value);
    setBookDialogAnchorEl(null);
    latTenINLocalStorage(
      localStorage.getItem("book_no") ?? 1,
      localStorage.getItem("chapter_no") ?? 1,
      value
    );
  };

  const changeScroll = async (book, chapter, verse) => {
    await changeBook(book);
    await changeChapter(chapter);
    await changeVerse(verse);
  };

  const containerRef = useRef();
  // const [selectedIndex, setSelectedIndex] = useState(0);

  const change = async (index) => {
    // setSelectedIndex(index);
    const selectedVerseButton = containerRef.current.children[index];
    if (selectedVerseButton) {
      selectedVerseButton.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  // const handlePrevious = () => {
  //   const newSelectedIndex = Math.max(0, selectedIndex - 2);
  //   change(newSelectedIndex);
  // };

  // const handleNext = () => {
  //   const newSelectedIndex = Math.min(lastTen.length - 1, selectedIndex + 2);
  //   change(newSelectedIndex);
  // };

  const handleFirst = () => {
    change(0);
  };

  const handleLast = () => {
    change(lastTen.length - 1);
  };

  const [verseClearDialogopen, setVerseClearDialogopen] = useState(false);

  const verseClearDialogClose = () => {
    setVerseClearDialogopen(false);
  };

  const handleVerseClearAllDialigOpen = () => {
    setVerseClearDialogopen(true);
  };

  const lastTenClear = () => {
    let array = [];
    localStorage.setItem("lastTenVerse", JSON.stringify(array));
    setLastTen([]);
    verseClearDialogClose();
  };

  const lastTenRender = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("lastTenVerse")) || [];
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];

    const bookNumberToNameMap = names.reduce((map, name, index) => {
      map[index + 1] = name;
      return map;
    }, {});

    return localStorageData.length > 0 ? (
      localStorageData.map((book, index) => {
        const { book: bookNumber, chapter, verse } = book;
        const bookName = bookNumberToNameMap[bookNumber].slice(0, 5);

        return (
          <button
            className="lastBookButton"
            key={index}
            onClick={() => changeScroll(bookNumber, chapter, verse)}
          >
            {`${bookName} ${chapter} : ${verse}`}
          </button>
        );
      })
    ) : (
      <Typography variant="body2">No last selected Verses</Typography>
    );
  };

  const [verseClearAllDialogOpen, setVerseClearAllDialogOpen] = useState(false);
  const [dialog, SetDialog] = useState(false);
  const [verseIndex, setVerseIndex] = useState(null);

  const handleVerseClearDialogOpen = () => {
    setVerseClearAllDialogOpen(true);
  };

  const handleVerseClearAllDialogClose = () => {
    setVerseClearAllDialogOpen(false);
  };

  const clearLastVerseIndividuallyDialogOpen = (index) => {
    SetDialog(true);
    setVerseIndex(index);
  };

  const clearLastVerseIndividuallyDialogClose = () => {
    SetDialog(false);
  };

  const clearLastVerseIndividuallyClickOk = () => {
    const data = JSON.parse(localStorage.getItem("lastTenVerse")) || [];
    const filterdData = data.filter((item, i) => {
      return i !== verseIndex;
    });
    localStorage.setItem("lastTenVerse", JSON.stringify(filterdData));
    clearLastVerseIndividuallyDialogClose();
    if (
      verseIndex == 0 &&
      JSON.parse(localStorage.getItem("lastTenVerse")).length == 0
    ) {
      setLastTen([]);
      handleVerseClearAllDialogClose();
    } else {
      setVerseIndex();
    }
  };

  const lastTenClearRender = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("lastTenVerse")) || [];
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];

    const bookNumberToNameMap = names.reduce((map, name, index) => {
      map[index + 1] = name;
      return map;
    }, {});
    return localStorageData.map((book, index) => {
      const { book: bookNumber, chapter, verse } = book;
      const bookName = bookNumberToNameMap[bookNumber];
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div>
            {index + 1}. {`${bookName} ${chapter} : ${verse}`}
          </div>
          <div>
            <RemoveCircle
              color="error"
              onClick={() => {
                clearLastVerseIndividuallyDialogOpen(index);
              }}
            />
          </div>
        </div>
      );
    });
  };

  const handleVersionsChange = (event) => {
    if (!event.target.checked) {
      // Remove
      const newVersions = versions.filter(
        (item) => item !== event.target.value
      );
      setVersions(newVersions);
    } else {
      // Add
      const newVersions = [...versions];
      newVersions.push(event.target.value);
      setVersions(newVersions);
    }
  };

  const handleSaveClick = (event) => {
    changeVersions(versions);
    setVerseLanguageDialog(false);
  };

  const renderOTBook = () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    const options = names
      .map((name, index) => {
        return (
          <div key={index}>
            <button
              className="bookButton"
              onClick={() => {
                handleDialogBookChange(Number(index) + 1);
              }}
            >
              {name}
            </button>
          </div>
        );
      })
      .slice(0, 39);
    return options;
  };
  const OTdesiredLanguage = () => {
    const OTestament = [];
    globalVersions.forEach((value, i) => {
      const languageObject = bookLanguage.find((item) => item[value]);
      OTestament.push(
        i === 0 && (
          <p key={i} className="old_new">
            {languageObject ? languageObject[value].old : ""}
          </p>
        )
      );
    });
    return OTestament;
  };
  const renderNTBook = () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    const options = names
      .map((name, index) => {
        return (
          <div key={index}>
            <button
              className="bookButton"
              onClick={() => {
                handleDialogBookChange(Number(index) + 1);
              }}
            >
              {name}
            </button>
          </div>
        );
      })
      .slice(39, 66);
    return options;
  };
  const NTdesiredLanguage = () => {
    const OTestament = [];
    globalVersions.forEach((value, i) => {
      const languageObject = bookLanguage.find((item) => item[value]);
      OTestament.push(
        i === 0 && (
          <p key={i} className="old_new">
            {languageObject ? languageObject[value].new : ""}
          </p>
        )
      );
    });
    return OTestament;
  };
  const renderDialogChapterOptions = () => {
    const options = [];
    for (let i = 1; i <= chapterCounts[Number(bookNo) - 1]; i++) {
      options.push(
        <div key={Number(i)}>
          <button
            className="book-button"
            onClick={() => {
              handleDialogChapterChange(Number(i));
            }}
          >
            {i}
          </button>
        </div>
      );
    }
    return options;
  };

  const renderDialogVerseOptions = () => {
    const options = [];
    for (let i = 1; i <= verseCount; i++) {
      options.push(
        <div key={i}>
          <button
            className="book-button"
            onClick={() => {
              handleDialogVerseChange(i);
            }}
          >
            {i}
          </button>
        </div>
      );
    }

    return options;
  };

  const toggleSecondary = () => {
    setisTabshidden(!isTabshidden);
  };

  const toggleVersionsModal = () => {
    setVerseLanguageDialog(true);
  };

  const closeVersionsModal = () => {
    setVersions(globalVersions);
    setVerseLanguageDialog(false);
  };

  const toggleSearchBox = (event) => {
    handleToggle("search");
    setMobileViewSearch(false);
  };

  const openCommentaryBox = (event) => {
    handleToggle("commentry");
  };

  const openCommentaryBoxMobile = () => {
    handleToggle("commentry");
    setMobileCommentry(true);
  };

  const openArticleBox = (event) => {
    handleToggle("article");
  };

  const openDictionaryBox = (event) => {
    handleToggle("dictionary");
  };

  const openTorreyBox = (event) => {
    handleToggle("torrey");
  };

  const openNoteListBox = (event) => {
    handleToggle("notes");
  };
  const openVerseView = () => {
    setVerseViewOpen(true);
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (event) => {
    setBookDialogAnchorEl(event.currentTarget);
    setTabValue(0);
  };

  const handleBookDialogClose = () => {
    setBookDialogAnchorEl(null);
  };

  const bookDialogopen = Boolean(bookDialogAnchorEl);
  const id = bookDialogopen ? "simple-popover" : undefined;

  return (
    <div className="bible-page-options">
      <Grid container spacing={{ xs: 0, lg: 2 }} alignItems="center">
        <Grid
          item
          sx={{ display: { xs: "none", lg: "block" } }}
          lg={1}
          order={{ xs: 1 }}
        >
          <div>
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              style={{
                background: "#3a65ff",
                color: "#fff",
                padding: "3px 10px",
                boxShadow: "none",
              }}
            >
              Books
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={10}
          lg={7}
          order={{ xs: 2, lg: 3 }}
          sx={{ display: { xs: "none", lg: "flex" }, justifyContent: "end" }}
        >
          <div className="options-toggles">
            <div className="primary-toggles">
              <button variant="outlined" onClick={toggleVersionsModal}>
                Bibles
              </button>
              <button
                className={`button search-button ${
                  toggles.search ? "active" : ""
                }`}
                variant="outlined"
                onClick={toggleSearchBox}
              >
                Search
              </button>
              <button
                className={`button commentary-button ${
                  toggles.commentry ? "active" : ""
                }`}
                variant="outlined"
                onClick={openCommentaryBox}
              >
                Commentary
              </button>
              <button
                className={`button dictionary-button ${
                  toggles.dictionary ? "active" : ""
                }`}
                variant="outlined"
                onClick={openDictionaryBox}
              >
                Dictionary
              </button>
              <button
                className={`button note-list-button ${
                  toggles.notes ? "active" : ""
                }`}
                variant="outlined"
                onClick={openNoteListBox}
              >
                Notes
              </button>
              <button
                className={`button torrey-button ${
                  toggles.torrey ? "active" : ""
                }`}
                variant="outlined"
                onClick={openTorreyBox}
              >
                Torrey's
              </button>
              <button
                className={`button article-button ${
                  toggles.article ? "active" : ""
                }`}
                variant="outlined"
                onClick={openArticleBox}
              >
                Articles
              </button>

              <button variant="outlined" onClick={openVerseView}>
                Projector
              </button>
            </div>
          </div>
        </Grid>
        <Grid
          item
          sx={{ display: { xs: "flex", lg: "none" }, width: "100%" }}
          lg={1}
          order={{ xs: 1 }}
        >
          <div className="options-toggles">
            <div className="primary-toggles">
              <button
                onClick={handleClick}
                style={{
                  background: "#3a65ff",
                  color: "#fff",
                  padding: "3px 10px",
                }}
              >
                Books
              </button>
              <Popover
                sx={{ maxWidth: 1000 }}
                open={bookDialogopen}
                onClose={handleBookDialogClose}
                anchorEl={bookDialogAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className="bookPaper">
                  <Tabs
                    value={tabValue}
                    onChange={handleTabValueChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Book" {...a11yProps(0)} />
                    <Tab label="Chapter" {...a11yProps(1)} />
                    <Tab label="Verse" {...a11yProps(2)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <div className="notMobile">
                      {OTdesiredLanguage()}
                      <div className="bookAlign">{renderOTBook()}</div>
                      {NTdesiredLanguage()}
                      <div className="NTbookAlign">{renderNTBook()}</div>
                    </div>
                    <div className="mobile">
                      <div>
                        {OTdesiredLanguage()}
                        <div>{renderOTBook()}</div>
                      </div>
                      <div>
                        {NTdesiredLanguage()}
                        <div>{renderNTBook()}</div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {renderDialogChapterOptions()}
                    </span>
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {renderDialogVerseOptions()}
                    </span>
                  </TabPanel>
                </div>
              </Popover>
              <button variant="outlined" onClick={toggleVersionsModal}>
                Bibles
              </button>
              <button
                className={`button search-button ${
                  toggles.search ? "active" : ""
                }`}
                variant="outlined"
                onClick={toggleSearchBox}
              >
                Search
              </button>

              <button
                variant="outlined"
                className="mobile-only"
                onClick={toggleSecondary}
              >
                {isTabshidden ? "More" : "Less"}
              </button>
            </div>
            {!isTabshidden ? (
              <div className={`secondary-toggles`}>
                <button
                  className={`button commentary-button ${
                    toggles.commentry ? "active" : ""
                  }`}
                  variant="outlined"
                  onClick={openCommentaryBoxMobile}
                >
                  Commentary
                </button>
                <button
                  className={`button dictionary-button ${
                    toggles.dictionary ? "active" : ""
                  }`}
                  variant="outlined"
                  onClick={openDictionaryBox}
                >
                  Dictionary
                </button>
                <button
                  className={`button note-list-button ${
                    toggles.notes ? "active" : ""
                  }`}
                  variant="outlined"
                  onClick={openNoteListBox}
                >
                  Notes
                </button>
                <button
                  className={`button torrey-button ${
                    toggles.torrey ? "active" : ""
                  }`}
                  variant="outlined"
                  onClick={openTorreyBox}
                >
                  Torrey's
                </button>
                <button
                  className={`button article-button ${
                    toggles.article ? "active" : ""
                  }`}
                  variant="outlined"
                  onClick={openArticleBox}
                >
                  Articles
                </button>
                <button variant="outlined" onClick={openVerseView}>
                  Projector
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={4} order={{ xs: 3, lg: 2 }}>
          <Stack direction="row" justifyContent="center">
            <FirstPage onClick={handleFirst} style={{ fontSize: 30 }} />
            {/* <NavigateBefore onClick={handlePrevious} style={{ fontSize: 30 }} /> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={lastTen.length !== 0 ? "" : "center"}
              spacing={1}
              className={classes.scrollableContainer}
              ref={containerRef}
            >
              {lastTenRender()}
            </Stack>
            {/* <NavigateNext onClick={handleNext} style={{ fontSize: 30 }} /> */}
            <LastPage onClick={handleLast} style={{ fontSize: 30 }} />
            {lastTen.length > 1 && (
              <Button
                variant="contained"
                style={{
                  background: "#3a65ff",
                  color: "#fff",
                  padding: "3px",
                  height: "fit-content",
                }}
                className="lastTenClearButton"
                onClick={() => {
                  handleVerseClearDialogOpen();
                }}
              >
                Clear
              </Button>
            )}
            {lastTen.length !== 0 && (
              <Button
                variant="contained"
                style={{
                  background: "#3a65ff",
                  color: "#fff",
                  padding: "3px",
                  height: "fit-content",
                  width: 85,
                  minWidth: 85,
                  marginLeft: 5,
                }}
                className="lastTenClearButton"
                onClick={() => {
                  handleVerseClearAllDialigOpen();
                }}
              >
                Clear All
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        open={verseLanguageDialog}
        onClose={closeVersionsModal}
        maxWidth="sm"
      >
        <div className="modal-content box versions-box">
          <div className="box-header">
            <h6>Languages</h6>
            <span className="close-button" onClick={closeVersionsModal}>
              &times;
            </span>
          </div>
          <div className="box-content">
            <div className="select-versions">
              <input
                type="checkbox"
                id="english_nkjv-checkbox"
                value="english_nkjv"
                checked={versions.includes("english_nkjv")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="english_nkjv-checkbox">English (NKJV)</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="malayalam-checkbox"
                value="malayalam"
                checked={versions.includes("malayalam")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="malayalam-checkbox">Malayalam (BSI OV)</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="hindi-checkbox"
                value="hindi"
                checked={versions.includes("hindi")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="hindi-checkbox">Hindi</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="tamil-checkbox"
                value="tamil"
                checked={versions.includes("tamil")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="tamil-checkbox">Tamil</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="kannada-checkbox"
                value="kannada"
                checked={versions.includes("kannada")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="kannada-checkbox">Kannada</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="telugu-checkbox"
                value="telugu"
                checked={versions.includes("telugu")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="telugu-checkbox">Telugu</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="gujarati-checkbox"
                value="gujarati"
                checked={versions.includes("gujarati")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="gujarati-checkbox">Gujarati</label>
            </div>
            <div className="select-versions">
              <input
                type="checkbox"
                id="bengali-checkbox"
                value="bengali"
                checked={versions.includes("bengali")}
                onChange={handleVersionsChange}
              />
              <label htmlFor="bengali-checkbox">Bengali</label>
            </div>
          </div>
          <div className="box-footer">
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={verseClearDialogopen}
        onClose={verseClearDialogClose}
        maxWidth="xs"
      >
        <DialogContent>
          <div>Are you sure you to clear the 'Last selected Verses' ?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => lastTenClear()}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={verseClearDialogClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={verseClearAllDialogOpen}
        onClose={handleVerseClearAllDialogClose}
        maxWidth="sm"
      >
        <DialogContent style={{ padding: "0px" }}>
          <div className="box-header">
            <h6>Last Ten Verses</h6>
            <span
              className="close-button"
              onClick={handleVerseClearAllDialogClose}
            >
              &times;
            </span>
          </div>
          <div style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                justifyContent: "center",
              }}
            >
              {lastTenClearRender()}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialog}
        onClose={clearLastVerseIndividuallyDialogClose}
        maxWidth="sm"
      >
        <DialogContent>
          <div>Are you sure you to clear the 'Verses' ?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => clearLastVerseIndividuallyClickOk()}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={clearLastVerseIndividuallyDialogClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
