import React, { useEffect, useState } from "react";
import Loading from "../../loading/Loading";
import { useParams } from "react-router-dom";
import { getArticleById } from "../../../services/articleService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@material-ui/core";

function UserArticleView({ articleId, BackToArticleLists }) {
  let { id } = useParams();
  const [specificArticle, setSpecificArticle] = useState({});
  const [loading, setLoading] = useState(true);

  const getSpecificArticle = (articles) => {
    let specificArticleId = id ? id : articleId;
    getArticleById(specificArticleId)
      .then((res) => {
        setSpecificArticle(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSpecificArticle();
  }, []);

  return (
    <div className="user-article-main">
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={BackToArticleLists}
      >
        Back
      </Button>
      {loading ? (
        <Loading />
      ) : (
        <div className="user-article-view">
          <div className="user-article-sheet">
            <div className="user-article-title">
              {specificArticle ? <h3> {specificArticle.title}</h3> : null}
            </div>
            <div>
              <div>
                <p
                  dangerouslySetInnerHTML={{ __html: specificArticle?.content }}
                ></p>
                <p className="user-credit">{specificArticle?.credits}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserArticleView;
