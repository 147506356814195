import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import UserSongsList from "./UserSongList";
import UserSongView from "./UserSongView";

function UserSongPage({ setCurrentPage }) {
  let match = useRouteMatch();
  useEffect(() => {
    setCurrentPage("songs");
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path="/songs" component={UserSongsList} />
        <Route path={`${match.path}/view-song/:id`}>
          <UserSongView />
        </Route>
      </Switch>
    </div>
  );
}

export default UserSongPage;
