import { useState } from "react";
import { editSermon } from "../../services/sermonService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CommonEditor from "../CommonEditor/CommonEditor";
import { Button } from "@material-ui/core";
// import './SermonEdit.css';

export default function SermonEdit({
  sermon,
  sermons,
  setSermons,
  setViewingSermon,
  setActiveTab,
}) {
  const [title, setTitle] = useState(sermon.title);
  const [titleError, setTitleError] = useState("");
  const [location, setLocation] = useState(sermon.location);
  const [locationError, setLocationError] = useState("");
  const [selectedDate, setSelectedDate] = useState(sermon.date);
  const [dateError, setDateError] = useState("");
  const [summary, setSummary] = useState(sermon.summary);
  const [summaryError, setSummaryError] = useState("");
  const [sermonText, setSermonText] = useState(sermon.sermon);
  const [sermonError, setSermonError] = useState("");

  const handleSaveClick = () => {
    let errors = false;
    if (title === "") {
      setTitleError("Title cannot be empty.");
      errors = true;
    }
    if (location === "") {
      setLocationError("Location cannot be empty.");
      errors = true;
    }
    if (selectedDate === "") {
      setDateError("Please select a date.");
      errors = true;
    }
    if (summary === "") {
      setSummaryError("Summary cannot be empty.");
      errors = true;
    }
    if (sermonText === "") {
      setSermonError("Sermon cannot be empty");
      errors = true;
    }

    if (errors) {
      return;
    }

    editSermon(
      sermon.id,
      title,
      location,
      selectedDate,
      summary,
      sermonText
    ).then((response) => {
      let temp = response.data;
      const newSermons = [];
      sermons.forEach((item) => {
        if (item.id === temp.id) {
          temp.user = item.user;
          newSermons.push(temp);
        } else {
          newSermons.push(item);
        }
      });
      setSermons(newSermons);
      setViewingSermon(temp);
      setActiveTab("view");
    });
  };

  return (
    <div>
      <Button
        onClick={() => {
          setActiveTab("index");
        }}
        startIcon={<ArrowBackIcon className="icon" />}
      >
        Back
      </Button>
      <div className="sermon-edit">
        <h1>Edit sermon</h1>
        <div className="edit-body">
          <div className="row">
            <div className="item">
              <label>
                Title
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{titleError}</span>
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="item">
              <label>
                Location
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{locationError}</span>
              <input
                type="text"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </div>
            <div className="item">
              <label>
                Date
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{dateError}</span>
              <input
                type="date"
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
                value={selectedDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="item">
              <label>
                Summary
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{summaryError}</span>
              <textarea
                value={summary}
                onChange={(e) => {
                  setSummary(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="item">
              <CommonEditor value={sermonText} onChangeText={setSermonText} />
            </div>
          </div>
          <div className="row float-right">
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
