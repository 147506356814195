import React, { useEffect, useState } from "react";
import Loading from "../../loading/Loading";
import { useParams, useHistory } from "react-router-dom";
import { getTorreyById } from "../../../services/torreyService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";

function UserTorreyView({ torreyId, BackToTorreyLists }) {
  let { id } = useParams();
  const history = useHistory();
  const [specificTorrey, setSpecificTorrey] = useState({});
  const [loading, setLoading] = useState(true);

  const getSpecificTorrey = (torreys) => {
    let specificTorreyId = id ? id : torreyId;
    getTorreyById(specificTorreyId)
      .then((res) => {
        setSpecificTorrey(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSpecificTorrey();
  }, []);

  // const BackToTorreyLists = () => {
  //   history.push(`/torreys-list`);
  // };

  return (
    <div className="user-article-main">
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={BackToTorreyLists}
      >
        Back
      </Button>
      {loading ? (
        <Loading />
      ) : (
        <div className="user-article-view">
          <div className="user-article-sheet">
            <div className="user-article-title">
              {/* <ArrowCircleLeftIcon className="back-to-article-btn" 
        onClick={BackToAdminArticleLists}/> */}

              {specificTorrey ? <h3> {specificTorrey.title}</h3> : null}
            </div>
            <div>
              <div>
                {/* <p>{specificTorrey?.content}</p> */}
                <p
                  style={{ whiteSpace: "pre-line" }}
                  dangerouslySetInnerHTML={{
                    __html: specificTorrey?.content.replaceAll("\n", "<br/>"),
                  }}
                />
                {/* <p className="user-credit">{specificTorrey?.credits}</p> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserTorreyView;
