import React, { useState, useEffect } from "react";
import {
  createArticles,
  editArticles,
  getArticleById,
} from "../../../services/articleService";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CommonEditor from "../../../components/CommonEditor/CommonEditor";

function AdminArticleCreate() {
  let { id } = useParams();
  let formDataInitialValue = {
    title: "",
    credits: "",
  };

  const [articleContents, setarticleContents] = useState(formDataInitialValue);
  const [articleBody, setarticleBody] = useState("");
  const history = useHistory();

  const [error, setError] = useState({
    title: false,
    credits: false,
    content: false,
    msg: "",
  });

  const checkError = () => {
    setError({
      title: articleContents.title === "" ? true : false,
      credits: articleContents.credits === "" ? true : false,
      content: articleBody === "" ? true : false,
      msg: "please fill valid data",
    });
  };

  const handleSubmit = (e) => {
    let data = {
      title: articleContents.title,
      credits: articleContents.credits,
      content: articleBody,
    };
    if (data.title === "" || data.content === "" || data.credits === "") {
      checkError();
    } else if (id) {
      editArticles(id, data)
        .then((res) => {
          history.push(`/admin/view-article/${id}`);
        })
        .catch((error) => {});
    } else {
      createArticles(data)
        .then((res) => {
          setarticleContents(formDataInitialValue);
          history.push(`articles`);
        })
        .catch((error) => {});
    }
  };

  const fetchArticleById = () => {
    if (id) {
      getArticleById(id)
        .then((res) => {
          setarticleContents({
            title: res.data.data.title,
            credits: res.data.data.credits,
          });
          setarticleBody(res.data.data.content);
        })
        .catch((error) => {});
    }
  };

  const BackToPage = () => {
    history.push(`articles`);
  };
  useEffect(() => {
    fetchArticleById();
  }, []);

  return (
    <div>
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={() => BackToPage()}
      >
        Back
      </Button>
      <div className="main-div">
        <h1 className="article"> {id ? "Edit" : "Create"} Article</h1>
        <div className="article-title">
          <div>
            <label>
              Article Title
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>
            <br />
            <input
              type="text"
              id="title"
              placeholder="Article Title"
              onChange={(e) =>
                setarticleContents({
                  ...articleContents,
                  title: e.target.value,
                })
              }
              value={articleContents.title}
              className="article-title-input"
            />
            {error.title ? (
              <div style={{ color: "red", textAlign: "left" }}>{error.msg}</div>
            ) : null}
          </div>

          <div>
            <label>
              Author Name
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>
            <br />
            <input
              type="text"
              placeholder="Author Name"
              className="author-input-titles article-credits-input"
              id="credits"
              onChange={(e) =>
                setarticleContents({
                  ...articleContents,
                  credits: e.target.value,
                })
              }
              value={articleContents.credits}
            />
            {error.credits ? (
              <div style={{ color: "red", textAlign: "left" }}>{error.msg}</div>
            ) : null}
          </div>
        </div>

        <div className="article-content">
          Article Content
          <span className="required" style={{ color: "#FF0000" }}>
            *
          </span>
          <CommonEditor
            classname="editor"
            value={articleBody}
            onChangeText={(e) => setarticleBody(e)}
          />
          {error.content ? (
            <div style={{ color: "red", textAlign: "left" }}> {error.msg} </div>
          ) : null}
        </div>

        <div className="submit-btn">
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AdminArticleCreate;
