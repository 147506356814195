import api from "./api";

export function createTorreys(body) {
  return api.post(`/torrey`, body);
}

export function editTorreys(id, body) {
  return api.post(`/torrey/${id}`, body);
}

export function deleteTorreys(id) {
  return api.delete(`/torrey/${id}`);
}

export function getTorreyById(id) {
  return api.get(`/torrey/${id}`);
}

export const torreyAPI = (page = 1, searchText = "", quantity = 10) =>
  api.get(
    `/torreys-list?page=${page}&search=${searchText}&quantity=${quantity}`
  );
