import React, { useEffect } from "react";
// import "./about.css";

function About({ setCurrentPage }) {
  useEffect(() => {
    setCurrentPage("about");
  }, []);

  return (
    <div className="about-page">
      <div className="container">
        <div className="list">
          <h1 className="about">About Lighthouse</h1>
          <div className="content">
            Lighthouse is a multi-language Bible software developed as an
            effective tool for Bible students and Bible teachers. It is cable of
            searching words or phrases in English and several Indian languages.
            The major features include:
            <div>
              <ul>
                <li>
                  <b>Bibles:</b>Eight Versions of Bible namely English(NKJV),
                  Malayalam(BSI OV), Hindi, Tamil, Kannada, Telugu, Gujarati and
                  Bengali.
                </li>
                <br />
                <li>
                  <b>Search:</b>Searching capability of words or phrases in any
                  language with printable list of search results with count and
                  references.
                </li>
                <br />
                <li>
                  <b>Notes:</b>Personal Notes facility is enabled with editing
                  feature against each verse of the Bible
                </li>
                <br />
                <li>
                  <b>Commentary:</b>Complete Bible Commentary (MHBC) on each
                  chapter of Bible
                </li>
                <br />
                <li>
                  <b>Dictionary:</b>Easton's Bible Dictionary
                </li>
                <br />
                <li>
                  <b>Torrey's:</b>Torrey’s Topical Text Book with hyperlinks
                </li>
                <br />
                <li>
                  <b>Articles:</b>Articles includes
                </li>
                <br />
                <li>
                  <b>Projector:</b>Projector facility of Bible verses for Public
                  services
                </li>
                <br />
                <li>
                  <b>Map:</b>Bible Maps
                </li>
                <br />
                <li>
                  <b>Calendar:</b>Calendar and meeting Scheduling Tool for
                  churches and individuals.
                </li>
                <br />
                <li>
                  <b>Sermon:</b>Sermon Preparation Tool for Pastors and Bible
                  Teachers.
                </li>
                <br />
                <li>
                  <b>Prayerlist:</b>Prayer List preparation tool.
                </li>
                <br />
                <li>
                  <b>Highlight Verse:</b>Multi-colour Highlighting facility for
                  Bible verses.
                </li>
                <br />
              </ul>
              <p>
                The personal data, sermons, Notes and Calendar is stored in the
                Cloud server so that it can be retrieved from anywhere across
                the globe.
                <br />
                <br />
                Lighthouse is platform independent. It can be availed from
                Windows based, Android based and iOS based systems.
              </p>
            </div>
          </div>
          <hr />
          <div className="text-center">
            Dr Raju K George (Co-ordinator of the software project)
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
