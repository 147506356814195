import api from "./api";

export function getEventsOfDate(date) {
  return api.get(`/events/events-of-date/${date}`, {});
}
export function getEventsOfthismonth(yearmonth) {
  return api.get(`/events/${yearmonth}`, {});
}
export function getEventsOfMonth(month) {
  return api.get(`/events/events-of-month/${month}`, {});
}
export function getEventsOftwodate(start_date, end_date) {
  return api.get(`/events?start_date=${start_date}&end_date=${end_date}`, {});
}
export function getEventsOfid(id) {
  return api.get(`/events/${id}`, {});
}
export function createEvent(
  date,
  endDate,
  timeFrom,
  timeTo,
  title,
  place,
  details
) {
  return api.post(
    "/events",
    {
      date: date,
      endDate: endDate,
      timeFrom: timeFrom,
      timeTo: timeTo,
      title: title,
      place: place,
      details: details,
    },
    {}
  );
}

export function editEvent(
  id,
  date,
  endDate,
  timeFrom,
  timeTo,
  title,
  place,
  details
) {
  return api.patch(
    `/events/${id}`,
    {
      date: date,
      endDate: endDate,
      timeFrom: timeFrom,
      timeTo: timeTo,
      title: title,
      place: place,
      details: details,
    },
    {}
  );
}

export function deleteEvent(id) {
  return api.delete(`/events/${id}`, {});
}
