import {
  Popover,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteSong, getSongById } from "../../../services/songService";

function SongView(props) {
  let { id } = useParams();
  const history = useHistory();
  const [lyric, setLyric] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e, id) => {
    setOpen(true);
    setSongDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeLyric = (e) => {
    getSongById(id)
      .then((res) => {
        setLyric(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    changeLyric();
  }, []);

  const BackToAdminSongLists = () => {
    history.push(`/admin/songs/`);
  };

  const editSong = () => {
    history.push(`/admin/edit-song/${id}`);
  };
  const onDeleteSongs = () => {
    deleteSong(id)
      .then((res) => {
        history.push(`/admin/songs/`);
      })
      .catch((error) => {});
  };
  const [loading, setLoading] = useState(true);
  const [songDeleteId, setSongDeleteId] = useState("");

  return (
    <div>
      <div className="icon-btns">
        <Button
          onClick={BackToAdminSongLists}
          startIcon={<ArrowBackIcon className="icon" />}
        >
          Back
        </Button>
        <div>
          <Button
            onClick={editSong}
            variant="outlined"
            style={{ color: "#4caf50" }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => handleClickOpen(e)}
            style={{ color: "#f44336" }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="song_page">
          <div className="song-sheet">
            <div className="song-title">
              {lyric ? (
                <h3 key={lyric.id}>{`${lyric.id}: ${lyric.title}`}</h3>
              ) : null}
            </div>
            <div className="song-lyric">
              <div className="admin-song-lang-1">
                {lyric.contents?.length > 0
                  ? lyric.contents
                      .sort((a, b) => (a.id > b.id ? 1 : -1))
                      .map((item, i) => (
                        <p
                          key={i}
                          dangerouslySetInnerHTML={{
                            __html: item.content.replaceAll("\n", "<br/>"),
                          }}
                        />
                      ))
                  : null}
              </div>
              {lyric && lyric.language_id == 3 ? null : (
                <div className="admin-song-lang-2">
                  {lyric.contents?.length > 0
                    ? lyric.contents
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .map((item, i) => (
                          <p
                            key={i}
                            dangerouslySetInnerHTML={{
                              __html: item.transliterature.replaceAll(
                                "\n",
                                "<br/>"
                              ),
                            }}
                          />
                        ))
                    : null}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteSongs(songDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SongView;
