import api from "./api";

export function getSermons() {
    return api.get('/sermons', {
        
    });
}

export function createSermon(title, location, date, summary, sermon) {
    return api.post('/sermons', {
        title: title,
        location: location,
        date: date,
        summary: summary,
        sermon: sermon,
    }, {
        
    })
}

export function editSermon(id, title, location, date, summary, sermon) {
    return api.patch(`/sermons/${id}`, {
        title: title,
        location: location,
        date: date,
        summary: summary,
        sermon: sermon,
    }, {
        
    })
}

export function deleteSermon(id) {
    return api.delete(`/sermons/${id}`, {
        
    })
}