import api from "./api";

export function createMaps(body) {
  return api.post(`/maps`, body);
}

export function editMaps(id, body) {
  return api.post(`/maps/${id}`, body);
}

export function deleteMaps(id) {
  return api.delete(`/maps/${id}`);
}

export function getMapsById(id) {
  return api.get(`/maps/${id}`);
}

export const mapAPI = (page = 1, searchText = "", quantity = 10) =>
  api.get(`/maps?page=${page}&search=${searchText}&quantity=${quantity}`);
