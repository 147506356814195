import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { bookNames } from "../../bibleMeta";
import { searchBible } from "../../services/bibleService";

export default function SearchBox({
  changeBookAndChapter,
  changeVerse,
  latTenINLocalStorage,
  toggles,
  handleClose,
  mobileViewSearch,
  setMobileViewSearch
}) {
  const [limit, setLimit] = useState("whole");
  const [rangeFrom, setRangeFrom] = useState(1);
  const [rangeTo, setRangeTo] = useState(66);
  const [searchVersion, setSearchVersion] = useState("english_nkjv");
  const [lastSearchTerm, setLastSearchTerm] = useState("");
  const [searchError, setSearchError] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const componentRef = useRef();

  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page { size: auto; margin: 10mm 10mm 10mm 20mm; }`,
  });

  const closeSearchBox = () => {
    handleClose('search');
  };

  const renderBookOptions = () => {
    const names = bookNames.english_nkjv;
    const options = names.map((name, index) => {
      return (
        <option key={Number(index) + 1} value={Number(index) + 1}>
          {name}
        </option>
      );
    });
    return options;
  };

  const handleVerseClick = (book, chapter, verse, version) => {
    changeBookAndChapter(book, chapter).then(() => {
      changeVerse(verse, 'search');
      latTenINLocalStorage(book, chapter, verse);
      setMobileViewSearch(true);
    });
  };

  const highlightSearchTerm = (verse) => {
    let index = verse.toLowerCase().indexOf(lastSearchTerm.toLowerCase());
    if (index !== -1 && lastSearchTerm !== "") {
      return (
        <>
          {verse.slice(0, index)}
          <span className="search-result-highlight">
            {verse.slice(index, index + lastSearchTerm.length)}
          </span>
          {verse.slice(index + lastSearchTerm.length, verse.length)}
        </>
      );
    }
    return verse;
  };

  const renderSearchResults = () => {
    const elements = searchResults.map((single, index) => {
      const version = single.version;
      const bookNo = single.book_no;
      const chapterNo = single.chapter_no;
      const verseNo = single.verse_no;
      let verse = highlightSearchTerm(single.verse);

      return (
        <div
          key={index}
          className="search-result"
          onClick={() => {
            handleVerseClick(bookNo, chapterNo, verseNo, version);
          }}
        >
          <div className="search-result-index">
            {bookNames["english_nkjv"][Number(bookNo) - 1] +
              " " +
              chapterNo +
              ": " +
              verseNo}
          </div>
          <div className="search-result-verse">{verse}</div>
        </div>
      );
    });

    return elements;
  };

  const handleLimitChange = (event) => {
    const value = event.target.value;
    setLimit(value);
    if (value === "whole") {
      setRangeFrom(1);
      setRangeTo(66);
    }
    if (value === "old") {
      setRangeFrom(1);
      setRangeTo(39);
    }
    if (value === "new") {
      setRangeFrom(40);
      setRangeTo(66);
    }
  };

  const handleRangeChange = (event) => {
    const name = event.target.name;
    if (name === "from") {
      setRangeFrom(event.target.value);
    } else if (name === "to") {
      setRangeTo(event.target.value);
    }
    setLimit("custom");
  };

  const handleVersionChange = (event) => {
    setSearchVersion(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const term = event.target[7].value;
    if (term === "") {
      setSearchError("Search term cannot be empty.");
    } else {
      setSearchError("");
      setLastSearchTerm(term);
      searchBible(searchVersion, rangeFrom, rangeTo, term).then((response) => {
        setSearchResults(response.data);
        if (response.data.length === 0) {
          setSearchError("No results found");
        }
      });
    }
  };

  return (toggles.search ? (
    <div className={`box search-box aside ${mobileViewSearch ? "mobile-hidden" : ""}`}>
      <div className="box-header">
        <h2>Search ({searchResults.length} verses found)</h2>
        <span className="close-button" onClick={closeSearchBox}>
          &times;
        </span>
      </div>
      <form className="search-form" onSubmit={handleSearchSubmit}>
        <div className="section search-limit-section">
          <div className="row">
            <div className="search-limit-item">
              <input
                type="radio"
                name="search-limit"
                value="whole"
                checked={limit === "whole"}
                onChange={handleLimitChange}
                id="search-limit-whole"
              />
              <label htmlFor="search-limit-whole">Whole bible</label>
            </div>
            <div className="search-limit-item">
              <input
                type="radio"
                name="search-limit"
                value="old"
                checked={limit === "old"}
                onChange={handleLimitChange}
                id="search-limit-old"
              />
              <label htmlFor="search-limit-old">Old Testaments</label>
            </div>
            <div className="search-limit-item">
              <input
                type="radio"
                name="search-limit"
                value="new"
                checked={limit === "new"}
                onChange={handleLimitChange}
                id="search-limit-new"
              />
              <label htmlFor="search-limit-new">New Testaments</label>
            </div>
          </div>
          <div className="row">
            <div className="search-limit-item custom">
              <input
                type="radio"
                name="search-limit"
                value="custom"
                checked={limit === "custom"}
                onChange={handleLimitChange}
                id="search-limit-custom"
              />
              <label htmlFor="search-limit-custom">Custom Range</label>
            </div>
            <div className="book-range">
              <label>From</label>
              <select
                name="from"
                value={rangeFrom}
                onChange={handleRangeChange}
              >
                {renderBookOptions()}
              </select>
            </div>
            <div className="book-range">
              <label>To</label>
              <select name="to" value={rangeTo} onChange={handleRangeChange}>
                {renderBookOptions()}
              </select>
            </div>
          </div>
        </div>
        <div className="section row">
          <div className="search-version">
            <label className="search-version-label">Version</label>

            <select
              className="search-version-select"
              onChange={handleVersionChange}
              value={searchVersion}
            >
              <option value="english_nkjv">English (NKJV)</option>
              <option value="bengali">Bengali</option>
              <option value="gujarati">Gujarati</option>
              <option value="hindi">Hindi</option>
              <option value="kannada">Kannada</option>
              <option value="malayalam">Malayalam</option>
              <option value="telugu">Telugu</option>
              <option value="tamil">Tamil</option>
            </select>
          </div>
          <input type="text" name="search-term" className="search-term-input" />
          <input
            type="submit"
            value="Search"
            className="search-submit-input"
            onKeyDown={(e) => (e.key === "Enter" ? renderSearchResults() : {})}
          />
        </div>
        <div className="search-error">{searchError}</div>
        {searchResults.length > 0 && (
          <div className="section row">
            <button className="print-button" onClick={handlePrintClick}>
              Print
            </button>
          </div>
        )}
      </form>
      <div className="box-content">
        <div className="search-results" ref={componentRef}>
          <div className="search-results-header">
            <h1>Search results for : {lastSearchTerm}</h1>
            <p className="search-print-count">
              Count : {searchResults.length} verses found
            </p>
          </div>
          {renderSearchResults()}
        </div>
      </div>
    </div>
  ) : null);
}
