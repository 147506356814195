import { Grid, Button } from "@material-ui/core";

export default function SermonIndex({
  setActiveTab,
  setViewingSermon,
  sermons,
}) {
  const renderSermon = (item, index) => {
    return (
      <div key={index}>
        <div
          className="sermon"
          onClick={() => {
            setViewingSermon(item);
            setActiveTab("view");
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "initial",
              alignItems: "center",
            }}
          >
            <p>{index + 1 + "."}&nbsp;</p>
            <div className="date">
              {new Date(item.date).toDateString().substring(4, 15)}
            </div>
          </div>
          <div className="title">{item.title}</div>
          <div className="summary">{item.summary}</div>
        </div>
      </div>
    );
  };

  const renderSermons = () => {
    const elements = [];
    sermons.forEach((item, index) => {
      elements.push(renderSermon(item, index));
    });
    return elements;
  };

  return (
    <div className="sermon-index">
      <div>
        <Grid container spacing={0} className="sermon-head">
          <Grid item xs={12} md={6}>
            <h2> Sermons </h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => {
                setActiveTab("create");
              }}
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#FFF" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="index-body">
        <div className="sermons">
          {sermons.length === 0 && <div>There are no sermons.</div>}
          {renderSermons()}
        </div>
      </div>
    </div>
  );
}
