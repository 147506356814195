import api from "./api"

export const getDictionaryWords = (query = null) => {
    return api.get('/dictionary', {
        params: {
            query: query,
        }
    });
}

export const addDictionaryWord = (word, meaning) => {
    return api.post('/dictionary', {
        word: word,
        meaning: meaning,
    }, {
        
    });
}

export const updateDictionaryWord = (id, word, meaning) => {
    return api.patch(`/dictionary/${id}`, {
        word: word,
        meaning: meaning,
    }, {
       
    })
};

export const deleteDictionaryWord = (id) => {
    return api.delete(`/dictionary/${id}`, {
        
    });
}