import api from "./api";

export function register(data) {
  return api.post("/auth/register", {
    name: data.name,
    email: data.email,
    password: data.password,
  });
}

export function login(data) {
  return api.post("/auth/login", {
    email: data.email,
    password: data.password,
  });
}

export function getUser() {
  return api.post(
    "/auth/user",
    {},
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }
  );
}

export function logout() {
  return api.post("/auth/logout", {}, {});
}
export function forgotPassword(data) {
  return api.post("/forgot-password", { email: data }, {});
}