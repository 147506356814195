import { bookNames, chapterCounts } from "../../bibleMeta";
// import "./BibleBox.css";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import { addHighlight, deleteHighlight } from "../../services/highlightService";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { useEffect } from "react";
import ChapterTitle from "./chapterTitle";
import {
  Box,
  Popover,
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  FileCopyOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import copy from "copy-to-clipboard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { EffectCreative, Navigation } from "swiper/modules";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";

export default function BibleBox({
  user,
  userIsLoading,
  bookNo,
  oldBookNo,
  chapterNo,
  oldChapterNo,
  title,
  verseNo,
  verseCount,
  globalVersions,
  bibleData,
  chapterNotes,
  setNoteVerseNo,
  chapterHighlights,
  setChapterHighlights,
  changeChapter,
  changeBook,
  changeVerse,
  handleToggle,
}) {
  const renderHeaders = () => {
    const headers = [];
    globalVersions.forEach((value, index) => {
      headers.push(
        <h2 key={Number(index) + 1}>
          {bookNames[value]
            ? bookNames[value][Number(oldBookNo - 1)]
            : bookNames["english_nkjv"][Number(oldBookNo - 1)]}
          &nbsp;
          {oldChapterNo}
        </h2>
      );
    });
    return headers;
  };

  const renderVerseVersions = (verse) => {
    const verseVersions = [];
    globalVersions.forEach((single) => {
      let verseStyle = {};
      if (chapterHighlights[verse]) {
        verseStyle = {
          backgroundColor: chapterHighlights[verse],
        };
      }
      if (bibleData[single]) {
        verseVersions.push(
          <div key={single} className={"column version version-" + single}>
            <span className="verse-number">{verse}.</span>
            <span className={`verse-text ${fontSize}`} style={verseStyle}>
              {bibleData[single][verse]}
            </span>
          </div>
        );
      }
    });

    return verseVersions;
  };

  const handleAddNoteClick = (verse) => {
    setNoteVerseNo(verse);
    handleToggle("noteAddBox");
  };

  const handleViewNoteClick = (verse) => {
    setNoteVerseNo(verse);
    handleToggle("notes");
  };

  const handleHighlightClick = (verse, color) => {
    addHighlight(oldBookNo, oldChapterNo, verse, "english_nkjv", color).then(
      (response) => {
        const newHighlights = { ...chapterHighlights };
        newHighlights[verse] = color;
        setChapterHighlights(newHighlights);
      }
    );
  };

  const handleHighlightRemoveClick = (verse) => {
    deleteHighlight(oldBookNo, oldChapterNo, verse).then(() => {
      let newHighlights = {};
      for (const [key, value] of Object.entries(chapterHighlights)) {
        if (String(key) !== String(verse)) {
          newHighlights[key] = value;
        } else {
        }
      }
      setChapterHighlights(newHighlights);
    });
  };

  const verseCopy = (verse) => {
    globalVersions.forEach((single) => {
      if (bibleData[single]) {
        const book = bookNames[single][bookNo - 1];
        copy(`${book} ${chapterNo}:${verse}. ${bibleData[single][verse]}`);
        toast.success("Copied to clipboard");
      }
    });
  };

  const renderVerseOptions = (verse) => {
    return (
      <div className="verse-options">
        <div className="primary-options">
          {chapterNotes[verse] && chapterNotes[verse].length !== 0 && (
            <>
              <button
                className="note-button"
                onClick={() => {
                  handleViewNoteClick(verse);
                }}
                title="View Note"
              >
                <MessageOutlinedIcon />
              </button>
            </>
          )}
        </div>
        <div className="secondary-options">
          <div className="highlight-buttons">
            <button
              onClick={() => {
                handleHighlightClick(verse, "#D5F4CB");
              }}
              className="highlight-green-button"
            ></button>
            <button
              onClick={() => {
                handleHighlightClick(verse, "#D2E8FF");
              }}
              className="highlight-blue-button"
            ></button>
            <button
              onClick={() => {
                handleHighlightClick(verse, "#F8D1DC");
              }}
              className="highlight-rose-button"
            ></button>
            <button
              onClick={() => {
                handleHighlightClick(verse, "#FFEEC9");
              }}
              className="highlight-gold-button"
            ></button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                handleHighlightRemoveClick(verse);
              }}
              className="highlight-none-button"
            >
              <NotInterestedIcon />
            </button>
            <button
              onClick={() => {
                handleAddNoteClick(verse);
              }}
              title="Add Note"
              className="addbutton"
            >
              <NoteAddOutlinedIcon />
            </button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                verseCopy(verse);
              }}
              title="Copy Verse"
            >
              <FileCopyOutlined />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderVerses = () => {
    const verses = [];
    for (let i = 1; i <= verseCount; i++) {
      verses.push(
        <div className="bible-verse" key={i} id={"bible-verse-" + i}>
          <div className="versions">{renderVerseVersions(i)}</div>
          {user && renderVerseOptions(i)}
        </div>
      );
    }
    return verses;
  };

  useEffect(() => {
    const bibleElement = document.querySelector(".bible-box .box-content");
    bibleElement.addEventListener("mouseup", (event) => {
      const searchInputElement = document.querySelector(
        ".search-box .search-term-input"
      );
      if (!bibleElement || !searchInputElement) {
        return;
      }
      let selection = "";
      if (window.getSelection) {
        selection = window.getSelection();
      } else if (document.selection) {
        selection = document.selection.createRange();
      }
      searchInputElement.value = selection;
    });
  }, []);

  const handleSlideChange = async (swipe) => {
    const { activeIndex, slides } = swipe;
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    if (swipe.swipeDirection === "next") {
      if (activeIndex === slides.length - 1) {
        // If last chapter move to 1st chapter.
        if (chapterCounts[Number(bookNo) - 1] < Number(chapterNo) + 1) {
          if (names.length < Number(bookNo) + 1) {
            await changeBook(1);
            await changeChapter(1);
            await changeVerse(1);
          } else {
            await changeBook(Number(bookNo) + 1);
            await changeChapter(1);
            await changeVerse(1);
          }
        } else {
          await changeChapter(Number(chapterNo) + 1);
          await changeVerse(1);
        }
      }
    }

    if (swipe.swipeDirection === "prev") {
      if (activeIndex === 0) {
        // Add a check to prevent setting chapterNo below 1
        if (Number(chapterNo) > 1) {
          await changeChapter(Number(chapterNo) - 1);
          await changeVerse(1);
        }
      }
    }
  };

  const handleNextChapter = async () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    if (chapterCounts[Number(bookNo) - 1] < Number(chapterNo) + 1) {
      if (names.length < Number(bookNo) + 1) {
        await changeBook(1);
        await changeChapter(1);
        await changeVerse(1);
      } else {
        await changeBook(Number(bookNo) + 1);
        await changeChapter(1);
        await changeVerse(1);
      }
    } else {
      await changeChapter(Number(chapterNo) + 1);
      await changeVerse(1);
    }
  };

  const handlePrevChapter = async () => {
    if (Number(chapterNo) > 1) {
      await changeChapter(Number(chapterNo) - 1);
      await changeVerse(1);
    }
  };
  const [fontSize, setFontSize] = useState("md"); // Initial font size

  const handleChange = (event) => {
    setFontSize(event.target.value);
  };

  return (
    <div className="box bible-box">
      <Toaster />
      <Stack direction="row" alignItems="center">
        <div className="box-header" style={{ width: "100%" }}>
          {renderHeaders()}
        </div>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 54 }}
          size="small"
        >
          <Select value={fontSize} onChange={handleChange}>
            <MenuItem value={"xs"}>A--</MenuItem>
            <MenuItem value={"sm"}>A-</MenuItem>
            <MenuItem value={"md"}>A</MenuItem>
            <MenuItem value={"lg"}>A+</MenuItem>
            <MenuItem value={"xl"}>A++</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <ChapterTitle book={bookNo} chapter={chapterNo} vers={globalVersions} />
      <div className="swiper">
        <div className="box-content">
          <Swiper
            // grabCursor={true}
            modules={[EffectCreative, Navigation]}
            className="mySwiper"
            slidesPerView={1}
            onSlideChange={handleSlideChange}
            // style={{ height: "100%" }}
            loop={true}
            effect="creative"
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            touchStartPreventDefault={false}
          >
            <SwiperSlide>{renderVerses()}</SwiperSlide>
            <SwiperSlide>{renderVerses()}</SwiperSlide>
          </Swiper>
        </div>
        <div className="swiper-button-prev" onClick={handlePrevChapter}></div>
        <div className="swiper-button-next" onClick={handleNextChapter}></div>
      </div>
    </div>
  );
}
