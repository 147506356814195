import { useEffect, useState } from "react";
import {
  createPrayerRequest,
  deletePrayerRequest,
  getPrayerListIndex,
} from "../../services/prayerService";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  CardContent,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
import PrayerRequestEditForm from "../../components/PrayerRequestEditForm/PrayerRequestEditForm";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import { Card, Grid, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function PrayerListPage({
  userIsLoading,
  user,
  setCurrentPage,
}) {
  const { id, groupName } = useParams();

  const [requestOpen, setRequestOpen] = useState(false);
  const [prayerList, setPrayerList] = useState([]);
  const [prayerGroups, setPrayerGroups] = useState([]);

  const [requestSubject, setRequestSubject] = useState("");
  const [requestSubjectError, setRequestSubjectError] = useState("");
  const [requestGroup, setRequestGroup] = useState("0");
  const [requestGroupError, setRequestGroupError] = useState("");
  const [requestMatter, setRequestMatter] = useState("");
  const [requestMatterError, setRequestMatterError] = useState("");
  const [editingPrayerRequest, setEditingPrayerRequest] = useState();
  const [deletingPrayerRequest, setDeletingPrayerRequest] = useState();
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [descriptionView, setDescriptionView] = useState(false);

  const handledClickOpen = () => {
    setRequestOpen(true);
  };
  const handledClose = () => {
    setRequestOpen(false);
  };

  const openDeleteForm = () => {
    setDeleteFormOpen(true);
  };
  const closeDeleteForm = () => {
    setDeleteFormOpen(false);
  };

  const openEditForm = () => {
    setEditFormOpen(true);
  };
  const closeEditForm = () => {
    setEditFormOpen(false);
  };

  const getPrayerList = () => {
    getPrayerListIndex(id).then((response) => {
      setPrayerList(response.data);
    });
  };

  useEffect(() => {
    getPrayerList();
    const object = JSON.parse(groupName);
    setPrayerGroups(object);
  }, []);

  useEffect(() => {
    setCurrentPage("prayerGroupList");
  }, []);

  const handleDeleteClick = (prayer) => {
    setDeletingPrayerRequest(prayer);
    openDeleteForm();
  };

  const confirmDeletion = (prayer) => {
    deletePrayerRequest(prayer.id).then(() => {
      const newPrayerlist = [];
      prayerList.forEach((item) => {
        if (item.id !== prayer.id) {
          newPrayerlist.push(item);
        }
      });
      setPrayerList(newPrayerlist);
      closeDeleteForm();
    });
  };

  const handleEditClick = (prayerRequest) => {
    setEditingPrayerRequest(prayerRequest);
    openEditForm();
  };

  const renderPrayerGroupOptions = () => {
    const options = [];

    prayerGroups.forEach((item, index) => {
      options.push(
        <option value={item.id} key={index}>
          {item.name}
        </option>
      );
    });

    return options;
  };
  const [desc, setDesc] = useState({
    sub: "",
    description: "",
  });
  const openDescriptionView = (description, subject) => {
    setDesc({
      sub: subject,
      description: description,
    });
    setDescriptionView(true);
  };
  const closeDescriptionView = () => {
    setDescriptionView(false);
    setDesc({
      sub: "",
      description: "",
    });
  };

  const renderPrayerRequest = (item, i) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={i}>
        <Card className="prayer-card">
          <div
            onClick={() => {
              openDescriptionView(item.description, item.subject);
            }}
          >
            <h4 className="text-center">{item.subject}</h4>
            <hr />
            <i>{new Date(item.created_at).toDateString().substring(4, 15)}</i>
            <p
              key={i}
              dangerouslySetInnerHTML={{
                __html:
                  item.description.length > 75
                    ? item.description.slice(0, 75) + "..."
                    : item.description.replaceAll("\n", "<br/>"),
              }}
            />
          </div>
          {item.user.id === user?.id && (
            <div className="display-inline">
              <Button
                onClick={() => {
                  handleEditClick(item);
                }}
                variant="text"
                style={{ color: "#4caf50" }}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  handleDeleteClick(item);
                }}
                style={{ color: "#f44336" }}
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          )}
        </Card>
      </Grid>
    );
  };

  const renderPrayerRequestList = () => {
    const elements = [];
    prayerList.forEach((item, index) => {
      elements.push(renderPrayerRequest(item, index));
    });

    return elements;
  };

  const handleAddPrayerRequestClick = () => {
    let errorOccured = false;
    if (requestSubject === "") {
      setRequestSubjectError("Subject cannot be empty.");
      errorOccured = true;
    } else {
      setRequestSubjectError("");
    }

    if (requestGroup === "0") {
      setRequestGroupError("Please select a group.");
      errorOccured = true;
    } else {
      setRequestGroupError("");
    }

    if (requestMatter === "") {
      setRequestMatterError("Prayer request field cannot be empty.");
      errorOccured = true;
    } else {
      setRequestMatterError("");
    }

    if (!errorOccured) {
      createPrayerRequest(requestGroup, requestSubject, requestMatter).then(
        (response) => {
          setRequestSubject("");
          setRequestGroup("0");
          setRequestMatter("");
          handledClose();
          let newPrayerList = [response.data, ...prayerList];
          setPrayerList(newPrayerList);
        }
      );
    }
  };

  if (!user) {
    return (
      <div className="dialog">
        <p className="dialog-box">
          You need to be logged in to use this feature.
        </p>
      </div>
    );
  }

  return (
    <div className="prayer-list-page main-bg">
      <div className="container">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="prayer-list-header">
            {/* <h1>Prayer List</h1> */}
            <Typography variant="h4">Prayer List</Typography>
          </div>
          <Button
            variant="contained"
            style={{
              background: "#3a65ff",
              color: "#fff",
              margin: "10px 5px",
            }}
            onClick={handledClickOpen}
          >
            Add Prayer Request
          </Button>
        </Stack>
        {prayerList.length > 0 ? (
          <div>
            <h2>{prayerList[0].prayer_group.name}</h2>
          </div>
        ) : (
          ""
        )}
        {prayerList.length > 0 ? (
          <Grid container spacing={2}>
            {renderPrayerRequestList()}
          </Grid>
        ) : (
          <div style={{ textAlign: "center" }}>No prayers found</div>
        )}
      </div>
      <Dialog
        open={requestOpen}
        onClose={handledClose}
        aria-labelledby="form-dialog-title"
        id="form-dialog"
      >
        <div className="close">
          <h3>Add A Prayer Request</h3>
          <span className="close-button" onClick={handledClose}>
            &times;
          </span>
        </div>
        <DialogContent>
          {!user && <div>You need to be loggged in to create group.</div>}
          {user && (
            <div className="form_item">
              <label>
                Subject
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <TextField
                variant="outlined"
                fullWidth
                placeholder="Subject"
                type="text"
                name="subject"
                value={requestSubject}
                onChange={(event) => {
                  setRequestSubject(event.target.value);
                }}
                style={{ marginBottom: 15 }}
              />
              <div>
                <span style={{ color: "red" }}>{requestSubjectError}</span>
              </div>
            </div>
          )}
          <div className="form_item">
            <label>
              Prayer Group
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>

            <select
              fullWidth
              value={requestGroup}
              onChange={(event) => {
                setRequestGroup(event.target.value);
              }}
              style={{ marginBottom: 15 }}
            >
              <option value="0">--Select--</option>
              {renderPrayerGroupOptions()}
            </select>
            <div>
              <span style={{ color: "red" }}>{requestGroupError}</span>
            </div>
          </div>
          <div className="form_item">
            <label>
              Prayer request
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>

            <TextField
              variant="outlined"
              fullWidth
              multiline
              minRows={2}
              placeholder="Prayer Request"
              type="text"
              name="Prayer request"
              onChange={(event) => {
                setRequestMatter(event.target.value);
              }}
              value={requestMatter}
              style={{ marginBottom: 15 }}
            />
            <div>
              <span style={{ color: "red" }}>{requestMatterError}</span>
            </div>
          </div>
        </DialogContent>
        <div className="dialog-msg">
          {user && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={handleAddPrayerRequestClick}
            >
              Create
            </Button>
          )}
        </div>
      </Dialog>
      <Dialog open={editFormOpen} onClose={closeEditForm}>
        <div className="close">
          <h3>Edit Prayer Request</h3>
          <span className="close-button" onClick={closeEditForm}>
            &times;
          </span>
        </div>
        <DialogContent>
          <PrayerRequestEditForm
            prayerRequest={editingPrayerRequest}
            prayerGroups={prayerGroups}
            prayerList={prayerList}
            setPrayerList={setPrayerList}
            closeEditForm={closeEditForm}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={deleteFormOpen}
        onClose={closeDeleteForm}
        confirm={() => confirmDeletion(deletingPrayerRequest)}
        cancel={closeDeleteForm}
      />
      <Dialog open={descriptionView} onClose={closeDescriptionView}>
        <div className="desc-dialog-padding">
          <div className="close">
            <h3>{desc.sub}</h3>
            <span className="close-button" onClick={closeDescriptionView}>
              &times;
            </span>
          </div>
          <DialogContent>
            <p
              dangerouslySetInnerHTML={{
                __html: desc.description.replaceAll("\n", "<br/>"),
              }}
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
