import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Loading from "../../loading/Loading";
import CustomPagination from "../../pagination/customPagination";
import { torreyAPI, getTorreyById } from "../../../services/torreyService";
import UserTorreyView from "./UserTorreyView";
// import {getSearchTorrey} from "../../services/torreyService"

function UserTorreyList() {
  const [loading, setLoading] = useState(true);
  const [torreyList, setTorreyList] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(1);
  const [specificTorrey, setSpecificTorrey] = useState({});
  const [openTorrey, setOpenTorrey] = useState(false);
  const [torreyId, setTorreyId] = useState("");

  const getTorreyList = (page = initialPage, search = searchText) => {
    setLoading(true);
    torreyAPI(page, search)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page, total },
        } = data;
        setinitialPage(current_page);
        setTotalPage(last_page);
        setTorreyList(Data);
        setLoading(false);
      })
      .catch((e) => {
        setinitialPage(1);
        setTotalPage(1);
        setTorreyList([]);
        setLoading(false);
      });
  };

  const getTorrey = (tor) => {
    let id = tor ? tor.id : 1;
    getTorreyById(id)
      .then((res) => {
        setSpecificTorrey(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getTorreyList();
    getTorrey();
  }, []);

  const viewTorreyById = (id) => {
    setTorreyId(id);
    setOpenTorrey(true);
  };

  return (
    <div className="main-background">
      {!openTorrey ? (
        <div className="user-article-body">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="Search-article">
                <div className="Search-box">
                  <input
                    className="Search-input"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setsearchText(e.target.value)}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? getTorreyList() : {}
                    }
                  />
                  {searchText ? (
                    <IconButton
                      aria-label="close"
                      color="secondary"
                      onClick={() => {
                        setsearchText("");
                        getTorreyList(1, "");
                      }}
                      type="button"
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                  <IconButton
                    aria-label="close"
                    onClick={getTorreyList}
                    type="button"
                    color="primary"
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </div>
            </Grid>
          </Grid>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div className="admin-card">
                <table>
                  <tbody>
                    {torreyList.length > 0
                      ? torreyList.map((torrey, index) => (
                          <tr key={index}>
                            <td className="song-number">
                              {(initialPage - 1) * 10 + (index + 1)}
                            </td>
                            <td
                              onClick={(openTorrey) =>
                                viewTorreyById(torrey.id)
                              }
                            >
                              {torrey.title}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="root">
                <CustomPagination
                  page={initialPage}
                  lastPage={TotalPage}
                  onChangeFn={getTorreyList}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <UserTorreyView
            torreyId={torreyId}
            BackToTorreyLists={() => setOpenTorrey(false)}
          />
        </div>
      )}
    </div>
  );
}

export default UserTorreyList;
