import api from "./api";

export function getVerseTitle_Single(bNo, ch, vers) {
  return api.get(
    `/bible/chapter-title/book/${bNo}/chapter/${ch}/version/${vers}`
  );
}

export function getAllChapterTitle(
  page = 1,
  searchText = "",
  version,
  quantity = 10
) {
  return api.get(
    `/bible/chapter-titles?page=${page}&title=${searchText}&quantity=${quantity}&version=${version}`
  );
}

export function deleteBookChapterTitle(id) {
  return api.delete(`/chapter-title/${id}`);
}

export function postChapterTitle(bookNumber, chapter, version, obj) {
  return api.post(
    `/bible/chapter-title/book/${bookNumber}/chapter/${chapter}/version/${version}`,
    obj
  );
}

export function getChapterTitle(bookNumber, chapter, version) {
  return api.get(
    `/bible/chapter-title/book/${bookNumber}/chapter/${chapter}/version/${version}`
  );
}
