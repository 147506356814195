import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { getUser, login } from "../../services/authService";
import {
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

export default function LoginPage({
  setUserIsLoading,
  setUser,
  changeBookAndChapter,
  setCurrentPage,
}) {
  const [formError, setFormError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    setCurrentPage("login");
  }, []);
  const gotoRegister = () => {
    history.push("/register");
  };
  const gotoPassword = () => {
    history.push("/forgotPassword");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Validating form data
    let errors = false;
    if (email === "") {
      setEmailError("Please enter your email.");
      errors = true;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Enter a password.");
      errors = true;
    } else {
      setPasswordError("");
    }

    if (errors) return;

    // Calling API.
    login({
      email: email,
      password: password,
    })
      .then((response) => {
        // Login success
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("token_type", response.data.token_type);
        getUserDetails();
      })
      .catch((error) => {
        setFormError(
          error?.response?.data?.message ?? "There was a problem loggin in."
        );
      });
  };

  if (localStorage.getItem("access_token")) {
    return <Redirect to="/" />;
  }
  const getUserDetails = () => {
    getUser()
      .then((response) => {
        // User data recived since access tken was valid.
        setUserIsLoading(false);
        setUser(response.data);
        window.location.reload();
      })
      .catch((error) => {
        setUserIsLoading(false);
        if (error.response?.status === 401) {
          // 401 means unauthenticated.
          // That is, the access token is invalid or expired.
          // So we will remove the token from local storage.
          // localStorage.removeItem("access_token");
          // localStorage.removeItem("token_type");
        }
      });
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit} className="login-page">
        <span>{formError}</span>
        <div>
          <h2>Sign In</h2>
        </div>
        <div className="input">
          <label>
            Email
            <span className="required" style={{ color: "#FF0000" }}>
              *
            </span>
          </label>

          <br />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon style={{ color: "#BDBDBD" }} />
                </InputAdornment>
              ),
            }}
          />
          <i>{emailError}</i>
        </div>
        <div className="input">
          <label>
            Password
            <span className="required" style={{ color: "#FF0000" }}>
              *
            </span>
          </label>

          <br />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon style={{ color: "#BDBDBD" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <i>{passwordError}</i>
        </div>
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
          >
            Login
          </Button>
        </div>
        <div className="gotopage">
          New User?
          <Button variant="outlined" onClick={gotoRegister}>
            create an account
          </Button>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button variant="text" onClick={gotoPassword}>
            forgot Password
          </Button>
        </div>
      </form>
    </div>
  );
}
