import { useEffect, useState } from "react";
import { bookNames } from "../../bibleMeta";
// import './VerseViewModal.css'

export default function VerseView({
  oldBookNo,
  oldChapterNo,
  globalVersions,
  bibleData,
  verseCount,
  setVerseViewOpen,
}) {
  const [currentVerse, setCurrentVerse] = useState(1);
  const [versePerSlide, setVersePerSlide] = useState(1);

  const closeVerseView = () => {
    setVerseViewOpen(false);
  };

  const renderVersions = (verseNo) => {
    const elements = [];
    globalVersions.forEach((version, index) => {
      elements.push(
        <div key={index} className="version">
          <h2 className="version-index">
            {bookNames[version]
              ? bookNames[version][Number(oldBookNo - 1)]
              : bookNames["english_nkjv"][Number(oldBookNo - 1)]}
            {oldChapterNo}: {verseNo}
          </h2>
          <div className="version-verse">{bibleData[version][verseNo]}</div>
        </div>
      );
    });
    return elements;
  };

  const renderVerses = () => {
    const elements = [];

    for (
      let i = Number(currentVerse);
      i < Number(currentVerse) + Number(versePerSlide);
      i++
    ) {
      if (i <= verseCount) {
        elements.push(
          <div key={i} className="verse-view-verse">
            {renderVersions(i)}
          </div>
        );
      }
    }

    return elements;
  };

  const renderVerseOptions = () => {
    const elements = [];
    for (let i = 1; i <= verseCount; i++) {
      elements.push(
        <option key={i} value={i}>
          Verse {i}
        </option>
      );
    }
    return elements;
  };

  const nextVerse = () => {
    if (currentVerse < Number(verseCount)) {
      setCurrentVerse(currentVerse + 1);
    }
  };

  const previousVerse = () => {
    if (currentVerse > 1) {
      setCurrentVerse(currentVerse - 1);
    }
  };

  const handleVerseChange = (event) => {
    setCurrentVerse(Number(event.target.value));
  };

  const projection = () => {
    const element = document.querySelector(".verse-view-modal");
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1;

    if (!isSafari) {
      element.requestFullscreen() ||
        element.webkitRequestFullscreen() ||
        element.msRequestFullscreen() ||
        element.mozRequestFullScreen() ||
        element.webkitEnterFullScreen();
    }
  };

  useEffect(() => {
    projection();
  }, []);

  return (
    <div className="modal verse-view-modal" tabIndex="0">
      <div className="verse-view-content">
        <div className="header">
          <div className="verse-view-controls">
            <button onClick={previousVerse}>&#60;</button>
            <select value={currentVerse} onChange={handleVerseChange}>
              {renderVerseOptions()}
            </select>
            <button onClick={nextVerse}>&#62;</button>
            <select
              value={versePerSlide}
              onChange={(event) => {
                setVersePerSlide(Number(event.target.value));
              }}
            >
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            Verse(s) per slide
          </div>
          <span className="close-button" onClick={closeVerseView}>
            &times;
          </span>
        </div>
        <div className="verse-view-verses">{renderVerses()}</div>
      </div>
    </div>
  );
}
