import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { getChapterTitle } from '../../../services/verseService';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Dialog, DialogContent } from '@material-ui/core';
import Loading from '../../../components/loading/Loading';
import { deleteBookChapterTitle } from '../../../services/verseService';

function AdminChapterTitleView() {
    let { bookNumber, chapter, version } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [specificChapterTitle, setSpecificChapterTitle] = useState({});
    const [anchor, setAnchor] = useState(false);

    const getChapterIndividually = async () => {
        let re = await getChapterTitle(bookNumber, chapter, version).then((res) => {
            let data = res.data.data;
            setSpecificChapterTitle(data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const BackToChapterTitle = () => {
        history.push("/admin/verses")
    }

    const editChapterTitle = () => {
        history.push(`/admin/verses-edit/book/${bookNumber}/chapter/${chapter}/version/${version}`)
    }

    useEffect(() => {
        getChapterIndividually();
    }, []);

    const handleOpenDeleteDialog = () => {
        setAnchor(true);
    }

    const onDeleteversetitle = (id) => {
        deleteBookChapterTitle(id).then((res) => {
            history.push("/admin/verses")
        });
    }

    return (
        <div>
            <div className="icon-btns">
                <Button
                    startIcon={<ArrowBackIcon className="icon" />}
                    onClick={BackToChapterTitle}
                >
                    Back
                </Button>
                <div>
                    <Button
                        onClick={editChapterTitle}
                        variant="outlined"
                        style={{ color: "#4caf50" }}
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleOpenDeleteDialog}
                        style={{ color: "#f44336" }}
                        startIcon={<DeleteIcon />}
                    >
                        Delete
                    </Button>
                </div>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="song">
                    <div className="song-sheet">
                        <div className="song-title">
                            {specificChapterTitle ? <h3>{specificChapterTitle.title}</h3> : null}
                        </div>
                        <div className="admin-article-sheet">
                            <div>
                                <p>Book Number : {specificChapterTitle.book_no}</p>
                                <p>Chapter : {specificChapterTitle.chapter_no}</p>
                                <p>Version : {specificChapterTitle.version}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Dialog
                open={anchor}
                onClose={() => setAnchor(false)}>
                <DialogContent>
                    <div>Are you sure to delete User ?</div>
                    <div className="confirmation-buttons">
                        <Button
                            onClick={() => onDeleteversetitle(specificChapterTitle ? specificChapterTitle.id : null)}
                            variant="outlined"
                            style={{ color: "#f44336", margin: "0 5px" }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => setAnchor(false)}
                            variant="outlined"
                            style={{ color: "#4caf50" }}
                        >
                            No
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AdminChapterTitleView;