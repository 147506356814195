import {
  deleteSong,
  getSongCategories,
  songAPI,
} from "../../../services/songService";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import Loading from "../../../components/loading/Loading";
import CustomPagination from "../../../components/pagination/customPagination";

function SongsList() {
  const history = useHistory();
  const location = useLocation();
  const [songList, setSongList] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(location.state?.page ?? 1);
  const [categoryId, setCategoryId] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e, id) => {
    setOpen(true);
    setSongDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [songDeleteId, setSongDeleteId] = useState("");

  const getSongList = (
    page = initialPage,
    search = searchText,
    category = categoryId
  ) => {
    setLoading(true);

    songAPI(page, search, category)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page },
        } = data;
        setinitialPage(current_page);
        setTotalPage(last_page);
        setSongList(Data);
        setLoading(false);
      })
      .catch((e) => {
        setinitialPage(1);
        setTotalPage(1);
        setSongList([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSongList();
  }, [categoryId]);
  useEffect(() => {
    songCategory();
  }, []);
  const viewSongById = (id) => {
    history.push({
      pathname: `/admin/view-song/${id}`,
      state: { page: initialPage },
    });
  };
  const onDeleteSongs = (id) => {
    deleteSong(id)
      .then((res) => {
        getSongList(1, "");
        handleClose(null);
      })
      .catch((error) => {});
  };

  const [loading, setLoading] = useState(true);
  const [songCategories, setSongCategories] = useState([]);
  const songCategory = () => {
    getSongCategories()
      .then((res) => {
        setSongCategories(res.data.data);
      })
      .catch((error) => {});
  };

  const onSelectSongCategory = (e) => {
    setCategoryId(e.target.value);
    setinitialPage(1);
  };

  return (
    <div className="main-bg">
      <div className="paddingX">
        <Grid container spacing={0} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={3}>
            <h2> SONGS </h2>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={() => {
                history.push("/admin/create-song");
              }}
            >
              CREATE SONG
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="Search-box">
              <div className="songs-input-box">
                <select id="category" onChange={onSelectSongCategory}>
                  <option value={0}>All Songs</option>

                  {songCategories.length > 0
                    ? songCategories?.map((song) => (
                        <option key={song.id} value={song.id}>
                          {song.text}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <input
                className="Search-input"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setsearchText(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? getSongList() : {})}
              />

              {searchText ? (
                <IconButton
                  aria-label="close"
                  color="secondary"
                  onClick={() => {
                    setsearchText("");
                    getSongList(1, "");
                  }}
                  type="button"
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                ""
              )}
              <IconButton
                aria-label="close"
                onClick={getSongList}
                type="button"
                color="primary"
              >
                <SearchIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <div className="admin-card">
            <table>
              <tbody>
                {songList.length > 0 ? (
                  songList?.map((song, index) => (
                    <tr key={song.id}>
                      <td className="song-number">
                        {(initialPage - 1) * 10 + (index + 1)}
                      </td>
                      <td onClick={() => viewSongById(song.id)}>
                        {song.title}
                      </td>
                      <td>{song.id}</td>
                      <td>
                        <Button
                          style={{ color: "#f44336" }}
                          onClick={(e) => handleClickOpen(e, song.id)}
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <span className="no-songs">No songs found</span>
                )}
              </tbody>
            </table>
          </div>
        )}
        <div>
          <div className="root">
            <CustomPagination
              page={initialPage}
              lastPage={TotalPage}
              onChangeFn={getSongList}
            />
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteSongs(songDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SongsList;
