import {
  DialogContent,
  Grid,
  Button,
  Dialog,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading/Loading";
import { bookNames } from "../../../bibleMeta";
import {
  getAllChapterTitle,
  deleteBookChapterTitle,
} from "../../../services/verseService";
import ClearIcon from "@material-ui/icons/Clear";
import CustomPagination from "../../../components/pagination/customPagination";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";

function AdminChapterTitle() {
  const history = useHistory();

  const [verseList, setverseList] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [versetitleDeleteId, setversetitleDeleteId] = useState("");
  const [anchor, setAnchor] = useState(false);
  const [chapterNo, setChapterNo] = useState(1);
  const [languageState, setlanguageState] = useState("English (NKJV)");
  const [versionSelect, setVersionSelect] = useState("");

  const versetiltleList = (
    page = initialPage,
    search = searchText,
    version = versionSelect
  ) => {
    setLoading(true);
    getAllChapterTitle(page, search, version)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page, total },
        } = data;

        setinitialPage(current_page);
        setTotalPage(last_page);
        let verse = [...verseList];
        verse.length = 0;
        Data.map((e, r) => {
          let version = e.version;
          if (bookNames.hasOwnProperty(version)) {
            let name = bookNames[version];
            let bookName = name[e.book_no - 1];
            verse.push({
              bookno: e.book_no,
              vervalue: e.version,
              book_no: bookName,
              chapter_no: e.chapter_no,
              id: e.id,
              title: e.title,
              version:
                version === "english_nkjv"
                  ? "English (NKJV)"
                  : version === "malayalam"
                  ? "Malayalam (BSI OV)"
                  : version,
            });
          }
        });
        setverseList(verse);
        setLoading(false);
      })
      .catch((error) => {
        setinitialPage(1);
        setTotalPage(1);
        setverseList([]);
        setLoading(false);
      });
  };

  const onDeleteversetitle = (id) => {
    setAnchor(false);
    deleteBookChapterTitle(id).then((res) => {});
    versetiltleList();
  };

  const openPopover = (e, id) => {
    setversetitleDeleteId(id);
    setAnchor(true);
  };

  const createVerse = () => {
    history.push("/admin/verses-create");
  };

  useEffect(() => {
    if (chapterNo == "") setChapterNo(1);
    if (languageState == "") setlanguageState("English (NKJV)");
  });

  useEffect(() => {
    versetiltleList();
  }, [versionSelect]);

  const verseTitleClick = async (e, bookno, chapter, version) => {
    history.push(
      `/admin/verses-view/book/${bookno}/chapter/${chapter}/version/${version}`
    );
  };

  const onSelectVersion = (e) => {
    setVersionSelect(e.target.value);
    setinitialPage(1);
  };
  return (
    <div className="main-bg">
      <div className="paddingX">
        <Grid container spacing={0} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={4}>
            <h2>CHAPTER TITLE </h2>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={createVerse}
            >
              CREATE CHAPTER TITLE
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="Search-box">
              <div className="songs-input-box">
                <select onChange={onSelectVersion}>
                  <option value={0}>All Versions</option>
                  <option value={"english_nkjv"}>{"English (NKJV)"}</option>
                  <option value={"malayalam"}>{"Malayalam (BSI OV)"}</option>
                  <option value={"hindi"}>{"Hindi"}</option>
                  <option value={"tamil"}>{"Tamil"}</option>
                  <option value={"kannada"}>{"Kannada"}</option>
                  <option value={"telugu"}>{"Telugu"}</option>
                  <option value={"gujarati"}>{"Gujarati"}</option>
                  <option value={"bengali"}>{"Bengali"}</option>
                </select>
              </div>
              <input
                className="Search-input"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setsearchText(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? versetiltleList() : {})}
              />
              {searchText ? (
                <IconButton
                  aria-label="close"
                  color="secondary"
                  onClick={() => {
                    setsearchText("");
                    versetiltleList(1, "");
                  }}
                  type="button"
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                ""
              )}

              <IconButton onClick={versetiltleList} color="primary">
                <SearchIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        {loading ? <Loading /> : null}
        <div className="admin-card">
          <table>
            <tbody>
              {verseList.length > 0
                ? verseList?.map((versetitle, index) => (
                    <tr key={index}>
                      <td className="song-number">
                        {(initialPage - 1) * 10 + (index + 1)}
                      </td>
                      <td
                        className="table-title-truncate"
                        onClick={(e) =>
                          verseTitleClick(
                            e,
                            versetitle.bookno,
                            versetitle.chapter_no,
                            versetitle.vervalue
                          )
                        }
                      >
                        {versetitle.title}
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                          <Button
                            style={{ color: "#f44336" }}
                            variant="outlined"
                            onClick={(e) => openPopover(e, versetitle.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
        <div className="root">
          <CustomPagination
            page={initialPage}
            lastPage={TotalPage}
            onChangeFn={versetiltleList}
          />
        </div>
      </div>
      <Dialog open={anchor} onClose={() => setAnchor(false)}>
        <DialogContent>
          <div>Are you sure to delete User ?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteversetitle(versetitleDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={() => setAnchor(false)}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminChapterTitle;
