import { useEffect, useState } from "react";
import { getCommentary } from "../../services/bibleService";

export default function CommentaryBox({ oldBookNo, oldChapterNo, toggles, handleClose, mobileCommentry, setMobileCommentry }) {
  const closeCommentaryBox = () => {
    handleClose('commentry');
    setMobileCommentry(false);
  };
  useEffect(() => {
    GetCommentary();
  }, [oldBookNo, oldChapterNo]);

  const [commentary, setCommentary] = useState("");
  const GetCommentary = () => {
    getCommentary(oldBookNo, oldChapterNo)
      .then((res) => {
        setCommentary(res.data);
      })
      .catch((err) => { });
  };

  return (toggles.commentry ? (
    <div className={`box commentary-box aside ${mobileCommentry ? "" : 'mobile-hidden'}`}>
      <div className="box-header">
        <h2>Commentary</h2>
        <span className="close-button" onClick={closeCommentaryBox}>
          &times;
        </span>
      </div>
      <div
        style={{ padding: "10px" }}
        dangerouslySetInnerHTML={{ __html: commentary }}
      />
    </div>
  ) : null);
}
