import api from "./api";


export function createArticles(body) {
    return  api.post(`/article`,body)
}

export function editArticles(id,body) {
    return  api.post(`/article/${id}`,body)
}

export function deleteArticles(id) {
    return  api.delete(`/article/${id}`)
}


export function getArticleById(id) {
    return  api.get(`/article/${id}`)
}

export const articleAPI = (page = 1, searchText = "", quantity = 10) =>
  api.get(`/articles?page=${page}&search=${searchText}&quantity=${quantity}`);