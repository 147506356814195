import React, { useState, useEffect } from "react";
import {
  createSong,
  getSongContentType,
  getSongCategories,
  getSongById,
  editSong,
} from "../../../services/songService";
import { useHistory, useParams } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import { Button } from "@material-ui/core";

function SongAdmin() {
  const History = useHistory();
  let { id } = useParams();
  let formDataInitialValue = {
    title: "",
    video_link: "",
    category: "",
    keywords: "",
    status: 1,
    contents: [{ id: "", song_id: "", content: "", type: "", order: "" }],
    language_id: "",
  };

  const [songContentType, setContentType] = useState([]);
  const [songCategories, setSongCategories] = useState([]);
  const [songBody, setSongBody] = useState(formDataInitialValue);
  const [error, setError] = useState({
    title: false,
    keywords: false,
    category: false,
    msg: "",
  });

  const checkError = () => {
    setError({
      title: songBody.title === "" ? true : false,
      keywords: songBody.keywords === "" ? true : false,
      category: songBody.category === "" ? true : false,
      msg: "please fill valid data",
    });
  };

  const viewSongList = () => {
    History.push("/admin/songs");
  };
  const onSongChange = (e) => {
    let newSongData = { ...songBody };
    newSongData[e.target.id] = e.target.value;
    setSongBody(newSongData);
  };

  const handleSubmit = () => {
    songBody.contents = lyrics;
    if (songBody.title === "" || songBody.keywords === "") {
      checkError();
    } else {
      if (id) {
        editSong(id, songBody)
          .then((res) => {
            History.push(`/admin/view-song/${id}`);
          })
          .catch((error) => {});
      } else {
        createSong(songBody)
          .then((res) => {
            setSongBody(formDataInitialValue);
            setLyrics([{ id: 0, song_id: 0, content: "", type: 2, order: 0 }]);
            History.push(`/admin/songs`);
          })
          .catch((error) => {});
      }
    }
  };

  const [lyrics, setLyrics] = useState([
    { id: 0, song_id: 0, content: "", type: 2, order: 0 },
  ]);

  const onSongLyrics = (index, e) => {
    let newContent = [...lyrics];
    newContent[index][e.target.id] = e.target.value;
    setLyrics(newContent);
  };

  const addLyrics = (index) => {
    setLyrics([
      ...lyrics,
      { id: index, song_id: index, content: "", type: 2, order: index },
    ]);
  };

  const removeLyrics = (index) => {
    const values = [...lyrics];
    if (index > -1) {
      values.splice(index, 1);
      songBody.contents.splice(index, 1);
    }
    setLyrics(values);
  };

  useEffect(() => {
    songContent();
    songCategory();
    fetchSongById();
  }, []);

  const songCategory = () => {
    getSongCategories()
      .then((res) => {
        setSongCategories(res.data.data);
      })
      .catch((error) => {});
  };

  const songContent = () => {
    getSongContentType()
      .then((res) => {
        setContentType(res.data.data);
      })
      .catch((error) => {});
  };
  const fetchSongById = () => {
    if (id) {
      getSongById(id)
        .then((res) => {
          setSongBody(res.data.data);
          setLyrics(res.data.data.contents);
        })
        .catch((error) => {});
    }
  };
  return (
    <div>
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={viewSongList}
      >
        Back
      </Button>
      <div className="dictionary-main-page">
        <div className="article">
          <h1>{id ? "Edit" : "Add"} Song</h1>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="song-row">
              <div className="song-input-box">
                <label>
                  Song title
                  <span className="required" style={{ color: "#FF0000" }}>
                    *
                  </span>
                </label>

                <input
                  placeholder="Eg: Daivam ദൈവം "
                  id="title"
                  onChange={onSongChange}
                  value={songBody.title}
                />
                {error.title ? (
                  <div style={{ color: "red", textAlign: "left" }}>
                    {error.msg}
                  </div>
                ) : null}
                <marquee style={{ color: " #ff1a1a" }}>
                  NOTE: Title and Keywords should start with English
                </marquee>
              </div>

              <div className="song-input-box">
                <label>
                  Song Category
                  <span className="required" style={{ color: "#FF0000" }}>
                    *
                  </span>
                </label>

                <select
                  id="category"
                  onChange={onSongChange}
                  value={songBody.category}
                >
                  <option>Select a category</option>
                  {songCategories.length > 0
                    ? songCategories?.map((song) => (
                        <option key={song.id} value={song.id}>
                          {song.text}
                        </option>
                      ))
                    : null}
                </select>
                {error.category ? (
                  <div style={{ color: "red", textAlign: "left" }}>
                    {error.msg}
                  </div>
                ) : null}
              </div>
              <div className="song-input-box">
                <label>
                  Song Keywords
                  <span className="required" style={{ color: "#FF0000" }}>
                    *
                  </span>
                </label>

                <input
                  placeholder="Eg: Daivam ദൈവം "
                  id="keywords"
                  onChange={onSongChange}
                  style={{ width: 400 }}
                  value={songBody.keywords}
                />
                {error.keywords ? (
                  <div style={{ color: "red", textAlign: "left" }}>
                    {error.msg}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="song-row">
              <div className="song-input-box">
                <label>Song Video Link</label>

                <input
                  id="video_link"
                  onChange={onSongChange}
                  value={songBody.video_link}
                />
              </div>
              <div className="song-input-box">
                <label>
                  Song Language
                  <span className="required" style={{ color: "#FF0000" }}>
                    *
                  </span>
                </label>
                <select
                  id="language_id"
                  name="language_id"
                  onChange={onSongChange}
                  value={songBody.language_id}
                >
                  <option>Select Language</option>
                  <option value={0}>Malayalam</option>
                  <option value={1}>Hindi</option>
                  <option value={2}>Tamil</option>
                  <option value={3}>English</option>
                </select>
              </div>
            </div>

            {lyrics.map((sm, index) => (
              <div className="song-input-box" key={index}>
                <label>
                  Song Lyrics&nbsp;
                  {index + 1}
                  <span
                    className="required"
                    style={{ color: "#FF0000" }}
                  ></span>
                </label>

                <div className="song-input-box">
                  <label>
                    Lyrics Type
                    <span className="required" style={{ color: "#FF0000" }}>
                      *
                    </span>
                  </label>

                  <select
                    id="type"
                    value={sm.type}
                    onChange={(event) => onSongLyrics(index, event)}
                  >
                    {songContentType.length > 0
                      ? songContentType?.map((song) => (
                          <option key={song.id} value={song.id}>
                            {song.text}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <textarea
                  rows="5"
                  id="content"
                  value={sm.content}
                  onChange={(event) => onSongLyrics(index, event)}
                />
                <div className="song-left-box">
                  <AddCircleIcon
                    onClick={() => addLyrics(index + 1)}
                    style={{
                      backgroundColor: "#fff",
                      color: "#4caf50",
                      fontSize: 28,
                    }}
                  />
                  <RemoveCircleRoundedIcon
                    onClick={() => removeLyrics(index)}
                    style={{
                      backgroundColor: "#fff",
                      color: "#f44336",
                      fontSize: 28,
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="song-left-box">
              <AddCircleIcon
                onClick={() => addLyrics(0)}
                style={{
                  backgroundColor: "#fff",
                  color: "#4caf50",
                  fontSize: 28,
                }}
              />
            </div>
            <div className="create-song-btn">
              <Button
                variant="contained"
                style={{ backgroundColor: "#3a65ff", color: "#fff" }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongAdmin;

// else{
//   if (songBody.title === "" || songBody.keywords === "") {
//       checkError();
//   }
