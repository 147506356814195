import { Button, Dialog, DialogContent } from "@material-ui/core";

export default function ConfirmationDialog({ open, onClose, confirm, cancel }) {
  return (
    <div className="confirmation-dialog">
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={confirm}
              variant="contained"
              style={{ backgroundColor: "#f44336", color: "#FFF",fontWeight: "bold", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={cancel}
              variant="contained"
              style={{ backgroundColor: "#4caf50", color: "#FFF",fontWeight: "bold" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
