import React, { useState, useEffect } from "react";
import { Grid, Card, IconButton } from "@mui/material";
import Loading from "../../components/loading/Loading";
import { mapAPI } from "../../services/mapService";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import CustomPagination from "../../components/pagination/customPagination";
import Carousel, { Modal, ModalGateway } from "react-images";

function Maps({ setCurrentPage }) {
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(1);
  const [mapList, setMapList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getMapList = (page = initialPage, search = searchText) => {
    setLoading(true);

    mapAPI(page, search)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page },
        } = data;

        setinitialPage(current_page);
        setTotalPage(last_page);
        setMapList(Data);
        setLoading(false);
      })
      .catch((e) => {
        setinitialPage(1);
        setTotalPage(1);
        setMapList([]);
        setLoading(false);
      });
  };
  useEffect(() => {
    getMapList();
    setCurrentPage("map");
  }, []);

  const openModal = (index) => {
    setSelectedIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="main-bg">
      <div className="paddingX">
        <Grid container spacing={1} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6}>
            <h2> Maps </h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="Search">
              <div className="Search-box">
                <input
                  className="Search-input"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                  onKeyDown={(e) => (e.key === "Enter" ? getMapList() : {})}
                />
                {searchText ? (
                  <IconButton
                    aria-label="close"
                    color="secondary"
                    type="button"
                    onClick={() => {
                      setsearchText("");
                      getMapList(1, "");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                <IconButton color="primary" onClick={getMapList}>
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={1} style={{ margin: "auto" }}>
            {mapList.length > 0 ? (
              mapList.map((map, index) => (
                <Grid item xs={12} sm={6} md={4} key={map.id}>
                  <Card
                    onClick={() => openModal(index)}
                    sx={{ padding: "5px 10px" }}
                  >
                    <div className="close">
                      <div>
                        <h4>{map.title}</h4>
                        <p>{map.description}</p>
                      </div>
                      <img src={map.img_url} alt={map.title} height="100px" />
                    </div>
                  </Card>
                </Grid>
              ))
            ) : (
              <div style={{ textAlign: "center", lineHeight: "2rem" }}>
                No Data Found
              </div>
            )}
          </Grid>
        )}
        <div style={{ margin: "10px auto" }}>
          <CustomPagination
            page={initialPage}
            lastPage={TotalPage}
            onChangeFn={getMapList}
          />
        </div>
      </div>

      <ModalGateway>
        {modalIsOpen && (
          <Modal onClose={closeModal}>
            <Carousel
              style={{ width: "95%" }}
              currentIndex={selectedIndex}
              views={mapList.map((map) => ({
                source: map.img_url,
                caption: map.title,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
}

export default Maps;
