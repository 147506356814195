import { Button, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
// import { useRouteMatch } from 'react-router-dom';
import {
  addDictionaryWord,
  deleteDictionaryWord,
  getDictionaryWords,
  updateDictionaryWord,
} from "../../../services/dictionaryService";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DictionaryMainPage() {
  // let match = useRouteMatch();

  const [words, setWords] = useState([]);
  const [letter, setLetter] = useState("a");

  const [editingWord, setEditingWord] = useState();
  const [deletingWord, setDeletingWord] = useState();

  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    getDictionaryWords(letter).then((response) => {
      setWords(response.data);
    });
  }, []);

  const refreshDictionary = () => {
    getDictionaryWords(letter).then((response) => {
      setWords(response.data);
    });
  };

  const changeLetter = (event) => {
    setLetter(event.target.value);
    getDictionaryWords(event.target.value).then((response) => {
      setWords(response.data);
    });
  };

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  const renderLetterOptions = () => {
    return alphabets.map((item) => {
      return (
        <option key={item} value={item}>
          {item.toUpperCase()}
        </option>
      );
    });
  };

  const renderWords = () => {
    return words.map((item, index) => {
      return (
        <div className="word-item" key={index}>
          <p>
            <span className="word-item-word">{item.word}</span>: {item.meaning}
          </p>
          <div className="word-item-options">
            <Button
              onClick={() => {
                setEditingWord(item);
                setEditFormOpen(true);
              }}
              variant="outlined"
              startIcon={<EditIcon style={{ color: "#4caf50" }} />}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setDeletingWord(item);
                setDeleteModalOpen(true);
              }}
              variant="outlined"
              startIcon={<DeleteIcon style={{ color: "#f44336" }} />}
            >
              Delete
            </Button>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="dictionary-main-page">
      <div className="header">
        <h1>Dictionary</h1>
        <Button
          variant="contained"
          style={{ backgroundColor: "#3a65ff", color: "#fff" }}
          onClick={() => {
            setAddFormOpen(true);
          }}
        >
          Add New Word
        </Button>
      </div>
      <div className="body">
        <div className="filter-section">
          <div className="filter-item">
            <label>
              Letter
              <span className="required" style={{ color: "#FF0000" }}></span>
            </label>

            <Select
              variant="outlined"
              onChange={changeLetter}
              value={letter}
              style={{ margin: "10px 0", width: 50 }}
            >
              {renderLetterOptions()}
            </Select>
          </div>
        </div>
        <div className="words-section">{renderWords()}</div>
        {addFormOpen && (
          <div className="modal">
            <div className="modal-content">
              <AddWordForm
                close={() => {
                  setAddFormOpen(false);
                  refreshDictionary();
                }}
              />
            </div>
          </div>
        )}
        {editFormOpen && (
          <div className="modal">
            <div className="modal-content">
              <EditWordForm
                editingWord={editingWord}
                close={() => {
                  setEditFormOpen(false);
                  refreshDictionary();
                }}
              />
            </div>
          </div>
        )}
        {deleteModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <DeleteForm
                deletingWord={deletingWord}
                close={() => {
                  setDeleteModalOpen(false);
                  refreshDictionary();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function AddWordForm({ close }) {
  const [word, setWord] = useState("");
  const [wordError, setWordError] = useState("");
  const [meaning, setMeaning] = useState("");
  const [meaningError, setMeaningError] = useState("");

  const handleAddClick = (closeAfterAdding = false) => {
    let error = false;
    if (word === "") {
      setWordError("Word cannot be empty");
      error = true;
    } else {
      setWordError("");
    }
    if (meaning === "") {
      setMeaningError("Meaning cannot be empty");
      error = true;
    } else {
      setMeaningError("");
    }
    if (error) return;

    addDictionaryWord(word, meaning).then((response) => {
      setWord("");
      setMeaning("");
      if (closeAfterAdding) {
        close();
      }
    });
  };

  return (
    <div className="add-word-form">
      <div className="form-header">
        <h2>Add word</h2>
        <span className="close-button" onClick={close}>
          &times;
        </span>
      </div>
      <div className="form-body">
        <div className="row-end">
          <div className="item">
            <label>
              Word
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>

            <input
              type="text"
              value={word}
              onChange={(e) => {
                setWord(e.target.value);
              }}
            />
            <label>
              {wordError}
              <span className="required" style={{ color: "#FF0000" }}></span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="item">
            <label>
              Meaning
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>

            <textarea
              value={meaning}
              onChange={(e) => {
                setMeaning(e.target.value);
              }}
            />
            <label>
              {meaningError}
              <span className="required" style={{ color: "#FF0000" }}></span>
            </label>
          </div>
        </div>
        <div className="row-end">
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3a65ff",
              color: "#fff",
              margin: "0 5px",
            }}
            onClick={() => {
              handleAddClick(false);
            }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
            onClick={() => {
              handleAddClick(true);
            }}
          >
            Add And Close
          </Button>
        </div>
      </div>
    </div>
  );
}

function EditWordForm({ editingWord, close }) {
  const [word, setWord] = useState("");
  const [wordError, setWordError] = useState("");
  const [meaning, setMeaning] = useState("");
  const [meaningError, setMeaningError] = useState("");

  useEffect(() => {
    setWord(editingWord.word);
    setMeaning(editingWord.meaning);
  }, []);

  const handleUpdateClick = () => {
    let error = false;
    if (word === "") {
      setWordError("Word cannot be empty");
      error = true;
    } else {
      setWordError("");
    }
    if (meaning === "") {
      setMeaningError("Meaning cannot be empty");
      error = true;
    } else {
      setMeaningError("");
    }
    if (error) return;
    updateDictionaryWord(editingWord.id, word, meaning).then((response) => {
      close();
    });
  };

  return (
    <div className="add-word-form">
      <div className="form-header">
        <h2>Update word</h2>
        <span className="close-button" onClick={close}>
          &times;
        </span>
      </div>
      <div className="form-body">
        <div className="row">
          <div className="item">
            <label>
              Word
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>

            <input
              type="text"
              value={word}
              onChange={(e) => {
                setWord(e.target.value);
              }}
            />
            <label>
              {wordError}
              <span className="required" style={{ color: "#FF0000" }}></span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="item">
            <label>
              Meaning
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>

            <textarea
              value={meaning}
              onChange={(e) => {
                setMeaning(e.target.value);
              }}
            />
            <label>
              {meaningError}
              <span className="required" style={{ color: "#FF0000" }}></span>
            </label>
          </div>
        </div>
        <div className="row">
          <Button
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
            onClick={() => {
              handleUpdateClick(false);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
}

function DeleteForm({ deletingWord, close }) {
  const handleDeleteClick = () => {
    deleteDictionaryWord(deletingWord.id).then((response) => {
      close();
    });
  };
  return (
    <div className="delete-form">
      <div>Are you sure you want to delete the word "{deletingWord.word}"?</div>
      <div className="delete-form-buttons">
        <Button
          variant="outlined"
          style={{ color: "#f44336" }}
          onClick={handleDeleteClick}
        >
          Yes
        </Button>
        <Button variant="outlined" style={{ color: "#4caf50" }} onClick={close}>
          No
        </Button>
      </div>
    </div>
  );
}
