import { TextField, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { editPrayerRequest } from "../../services/prayerService";

export default function PrayerRequestEditForm({
  prayerRequest,
  prayerGroups,
  prayerList,
  setPrayerList,
  closeEditForm,
}) {
  const [requestSubject, setRequestSubject] = useState("");
  const [requestSubjectError, setRequestSubjectError] = useState("");
  const [requestGroup, setRequestGroup] = useState("0");
  const [requestGroupError, setRequestGroupError] = useState("");
  const [requestMatter, setRequestMatter] = useState("");
  const [requestMatterError, setRequestMatterError] = useState("");

  useEffect(() => {
    setRequestSubject(prayerRequest.subject);
    setRequestGroup(prayerRequest.prayer_group_id);
    setRequestMatter(prayerRequest.description);
  }, [prayerRequest]);

  const renderPrayerGroupOptions = () => {
    const options = [];

    prayerGroups.forEach((item, index) => {
      options.push(
        <option value={item.id} key={index}>
          {item.name}
        </option>
      );
    });

    return options;
  };

  const handleUpdateClick = () => {
    let errorOccured = false;
    if (requestSubject === "") {
      setRequestSubjectError("Subject cannot be empty.");
      errorOccured = true;
    } else {
      setRequestSubjectError("");
    }

    if (requestGroup === "0") {
      setRequestGroupError("Please select a group.");
      errorOccured = true;
    } else {
      setRequestGroupError("");
    }

    if (requestMatter === "") {
      setRequestMatterError("Prayer request field cannot be empty.");
      errorOccured = true;
    } else {
      setRequestMatterError("");
    }

    if (!errorOccured) {
      editPrayerRequest(
        prayerRequest.id,
        requestGroup,
        requestSubject,
        requestMatter
      ).then((response) => {
        let newPrayerList = prayerList.map((item) => {
          return item.id === prayerRequest.id ? response.data : item;
        });
        setPrayerList(newPrayerList);
        closeEditForm();
      });
    }
  };

  return (
    <div className="prayer-request-edit-form">
      <div>
        <label>
          Subject
          <span className="required" style={{ color: "#FF0000" }}>
            *
          </span>
        </label>

        <TextField
          variant="outlined"
          fullWidth
          placeholder="Subject"
          type="text"
          name="subject"
          value={requestSubject}
          onChange={(event) => {
            setRequestSubject(event.target.value);
          }}
          style={{ marginBottom: 15 }}
        />
        <div>
          <span>{requestSubjectError}</span>
        </div>
      </div>
      <div>
        <label>
          Prayer Group
          <span className="required" style={{ color: "#FF0000" }}>
            *
          </span>
        </label>

        <select
          fullWidth
          value={requestGroup}
          onChange={(event) => {
            setRequestGroup(event.target.value);
          }}
          style={{ marginBottom: 15 }}
        >
          <option value="0">--Select--</option>
          {renderPrayerGroupOptions()}
        </select>
        <div>
          <span>{requestGroupError}</span>
        </div>
      </div>
      <div>
        <label>
          Prayer request
          <span className="required" style={{ color: "#FF0000" }}>
            *
          </span>
        </label>

        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          placeholder="Prayer Request"
          type="text"
          name="Prayer request"
          onChange={(event) => {
            setRequestMatter(event.target.value);
          }}
          value={requestMatter}
          style={{ marginBottom: 15 }}
        />
        <div>
          <span>{requestMatterError}</span>
        </div>
      </div>
      <div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{ backgroundColor: "#3a65ff", color: "#fff" }}
          onClick={handleUpdateClick}
        >
          Update Prayer Request
        </Button>
      </div>
    </div>
  );
}
