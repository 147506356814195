import React, { useState, useEffect } from "react";
import {
  IconButton,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import {
  getEventsOfthismonth,
  getEventsOftwodate,
  deleteEvent,
} from "../../services/eventsService";
import dateFormat from "dateformat";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Pagination } from "@material-ui/lab";
import Loading from "../../components/loading/Loading";
import ClearIcon from "@material-ui/icons/Clear";
import { Stack, Typography } from "@mui/material";

export default function CalendarPage({ useIsLoading, user, setCurrentPage }) {
  const historty = useHistory();
  const [alleventInfo, setalleventInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [eveStatus, seteveStatus] = useState("all");
  const excludeColumns = ["id", "color"];
  const [eventDeleteId, setEventDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const [currentPag, setCurrentPag] = useState(1);
  const postsPerPage = 10;
  const indexOfLastPost = currentPag * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = alleventInfo.slice(indexOfFirstPost, indexOfLastPost);

  const pageLoad = async () => {
    setLoading(true);
    await getEventsOftwodate("1900-01-01", "9022-06-06").then((response) => {
      let allDetails = response.data.data;
      let alls = allDetails.map((e) => ({
        id: e.id,
        start_date: dateFormat(e.date),
        end_date: dateFormat(e.end_date),
        date: dateFormat(e.date, "dd"),
        enddate: dateFormat(e.end_date, "dd"),
        month: dateFormat(e.date, "mmmm"),
        year: dateFormat(e.date, "yyyy"),
        details: e.details,
        place: e.place,
        timeFrom: new Date("1970-01-01T" + e.from + "Z").toLocaleTimeString(
          "en-US",
          {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }
        ),
        timeTo: new Date("1970-01-01T" + e.to + "Z").toLocaleTimeString(
          "en-US",
          {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }
        ),
        from: e.from,
        to: e.to,
        title: e.title,
      }));
      setalleventInfo(alls.reverse());
      setLoading(false);
    });
  };

  const handleSearchButtonClick = () => {
    let result = [];
    const lowercasedValue = searchText.toLowerCase().trim();

    if (lowercasedValue === "") {
      if (eveStatus === "all") {
        pageLoad();
      } else if (eveStatus === "month") {
        getEventmonth();
      } else if (eveStatus === "day") {
        getEventday();
      } else if (eveStatus === "week") {
        getEventweek();
      }
    } else {
      result = alleventInfo.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setalleventInfo(result);
    }
  };

  const oneventChange = async (e) => {
    const value = e.target.value;
    alleventInfo.length = 0;

    switch (value) {
      case "all":
        seteveStatus("all");
        pageLoad();
        break;
      case "month":
        getEventmonth();
        break;
      case "week":
        getEventweek();
        break;
      case "day":
        getEventday();
        break;
      default:
        break;
    }
  };

  function getEventmonth() {
    seteveStatus("");
    var date = new Date();
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var currentDate = year + month;
    const tempArray = [];
    getEventsOfthismonth(currentDate).then((response) => {
      const allDetails1 = response.data.data;
      allDetails1.map((e, ei) => {
        tempArray.push({
          id: allDetails1[ei].id,
          start_date: dateFormat(e.date),
          end_date: dateFormat(e.end_date),
          date: dateFormat(allDetails1[ei].date, "dd"),
          enddate: dateFormat(allDetails1[ei].end_date, "dd"),
          month: dateFormat(allDetails1[ei].date, "mmmm"),
          year: dateFormat(allDetails1[ei].date, "yyyy"),
          details: allDetails1[ei].details,
          place: allDetails1[ei].place,
          timeFrom: new Date(
            "1970-01-01T" + allDetails1[ei].from + "Z"
          ).toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }),
          timeTo: new Date(
            "1970-01-01T" + allDetails1[ei].to + "Z"
          ).toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          }),
          from: allDetails1[ei].from,
          to: allDetails1[ei].to,
          title: allDetails1[ei].title,
        });
      });
      setalleventInfo(tempArray);
      setLoading(false);
      seteveStatus("month");
    });
  }

  function getEventweek() {
    seteveStatus("");
    const todayDate = new Date();
    const seventhDay = new Date();
    seventhDay.setDate(seventhDay.getDate() + 7);

    const getYearTodays = todayDate.getFullYear();
    const getMonthTodays = todayDate.getMonth() + 1;
    const getDateTodays = todayDate.getDate();

    const getYearNextWeek = seventhDay.getFullYear();
    const getMonthNextWeek = seventhDay.getMonth() + 1;
    const getDateNextWeek = seventhDay.getDate();

    let today = `${getYearTodays}-${getMonthTodays}-${getDateTodays}`;
    let nextWeek = `${getYearNextWeek}-${getMonthNextWeek}-${getDateNextWeek}`;

    setalleventInfo([]);
    getEventsOftwodate(today, nextWeek).then((response) => {
      const allDetails = response.data.data;

      allDetails.forEach((e, ei) => {
        const monthsd = moment(allDetails[ei].date, "YYYY-MM-DD").isoWeek();
        const week = new Date();
        const year = moment(week, "MM-DD-YYYY").isoWeek();

        if (year === monthsd) {
          setalleventInfo((oldArray) => [
            ...oldArray,
            {
              id: allDetails[ei].id,
              date: dateFormat(allDetails[ei].date, "dd"),
              enddate: dateFormat(allDetails[ei].end_date, "dd"),
              month: dateFormat(allDetails[ei].date, "mmmm"),
              year: dateFormat(allDetails[ei].date, "yyyy"),
              details: allDetails[ei].details,
              place: allDetails[ei].place,
              timeFrom: new Date(
                `1970-01-01T${allDetails[ei].from}Z`
              ).toLocaleTimeString("en-US", {
                timeZone: "UTC",
                hour12: true,
                hour: "numeric",
                minute: "numeric",
              }),
              timeTo: new Date(
                `1970-01-01T${allDetails[ei].to}Z`
              ).toLocaleTimeString("en-US", {
                timeZone: "UTC",
                hour12: true,
                hour: "numeric",
                minute: "numeric",
              }),
              from: allDetails[ei].from,
              to: allDetails[ei].to,
              title: allDetails[ei].title,
            },
          ]);
        }
      });
      setLoading(false);
      seteveStatus("week");
    });
  }

  function getEventday() {
    seteveStatus("");
    let todayDate = new Date();
    let getYear = todayDate.getFullYear();
    let getMonth = todayDate.getMonth() + 1;
    let getDate = todayDate.getDate();

    let date = `${getYear}-${getMonth}-${getDate}`;

    setalleventInfo([]);
    getEventsOftwodate(date, date).then((response) => {
      const allDetails = response.data.data;

      allDetails.forEach((e, ei) => {
        setalleventInfo((oldArray) => [
          ...oldArray,
          {
            id: allDetails[ei].id,
            date: dateFormat(allDetails[ei].date, "dd"),
            enddate: dateFormat(allDetails[ei].end_date, "dd"),
            month: dateFormat(allDetails[ei].date, "mmmm"),
            year: dateFormat(allDetails[ei].date, "yyyy"),
            details: allDetails[ei].details,
            place: allDetails[ei].place,
            timeFrom: new Date(
              `1970-01-01T${allDetails[ei].from}Z`
            ).toLocaleTimeString("en-US", {
              timeZone: "UTC",
              hour12: true,
              hour: "numeric",
              minute: "numeric",
            }),
            timeTo: new Date(
              `1970-01-01T${allDetails[ei].to}Z`
            ).toLocaleTimeString("en-US", {
              timeZone: "UTC",
              hour12: true,
              hour: "numeric",
              minute: "numeric",
            }),
            from: allDetails[ei].from,
            to: allDetails[ei].to,
            title: allDetails[ei].title,
          },
        ]);
      });

      setLoading(false);
      seteveStatus("day");
    });
  }

  const createEventClick = () => {
    historty.push(`/create-event`);
  };

  const handleEditClick = (item) => {
    console.log(item);
    historty.push(`/edit-event/${JSON.stringify(item)}`);
  };

  const handleDeleteClick = (item) => {
    setOpen(true);
    setEventDeleteId(item);
  };

  const handleClose = () => {
    setOpen(false);
    setEventDeleteId("");
  };

  const onDeleteMap = (id) => {
    deleteEvent(id)
      .then((res) => {
        pageLoad();
        handleClose();
      })
      .catch((error) => {});
  };

  // handle pagination page change
  const handlePageChange = (event, value) => {
    setCurrentPag(value);
  };

  useEffect(() => {
    pageLoad();
    setCurrentPage("calendar");
  }, []);

  return (
    <div className="calendar-page main-bg">
      <div className="paddingX">
        <Grid container spacing={1} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={3}>
            <h2> Upcoming Events </h2>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              color="primary"
              variant="contained"
              onClick={createEventClick}
            >
              Create Event
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="Search-box">
              <div className="songs-input-box">
                <select id="category" onChange={(e) => oneventChange(e)}>
                  <option value="all">All</option>
                  <option value="month">This Month</option>
                  <option value="week">This Week</option>
                  <option value="day">Today</option>
                </select>
              </div>
              <input
                style={{ fontWeight: "400" }}
                className="Search-input"
                placeholder="Search..."
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? pageLoad() : {})}
              />
              {searchText ? (
                <IconButton
                  aria-label="close"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    setSearchText("");
                    pageLoad();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                ""
              )}

              <IconButton color="primary" onClick={handleSearchButtonClick}>
                <SearchIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>

        {loading ? (
          <Loading />
        ) : (
          <>
            {currentPosts.length > 0 ? (
              currentPosts.map((e, i) => (
                <Stack
                  sx={{
                    display: { xs: "block", md: "none" },
                    background: "#fff",
                    borderRadius: 1,
                    p: 1,
                    my: 1,
                  }}
                >
                  <p>
                    <b>Date :</b>
                    {`${
                      e.date === e.enddate
                        ? e.date + " " + e.month + " " + e.year
                        : e.date +
                          " to " +
                          e.enddate +
                          " " +
                          e.month +
                          " " +
                          e.year
                    }`}
                  </p>
                  <p>
                    <b>Time :</b>
                    {e.timeFrom} to {e.timeTo}
                  </p>
                  <p>
                    <b>Title: </b>
                    {e.title}
                  </p>
                  <p>
                    <b>Place: </b>
                    {e.place}
                  </p>
                  <p>
                    <b>Details :</b>
                    {e.details}
                  </p>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <Tooltip title="Edit">
                      <IconButton>
                        <EditIcon
                          style={{ color: "#4caf50" }}
                          onClick={() => {
                            handleEditClick(e);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton>
                        <DeleteIcon
                          color="secondary"
                          onClick={() => {
                            handleDeleteClick(e.id);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              ))
            ) : (
              <Typography variant="body2"> No Data Found</Typography>
            )}
            <Stack sx={{ display: { xs: "none", md: "flex" } }}>
              <div className="admin-card">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Title</th>
                      <th>Place</th>
                      <th>Details</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPosts.length > 0 ? (
                      currentPosts.map((e, i) => (
                        <tr key={i}>
                          <td>
                            {`${
                              e.date === e.enddate
                                ? e.date + " " + e.month + " " + e.year
                                : e.date +
                                  " to " +
                                  e.enddate +
                                  " " +
                                  e.month +
                                  " " +
                                  e.year
                            }`}
                          </td>
                          <td>
                            {e.timeFrom} to {e.timeTo}
                          </td>
                          <td>{e.title}</td>
                          <td>{e.place}</td>
                          <td>{e.details}</td>
                          <td>
                            <Tooltip title="Edit">
                              <IconButton>
                                <EditIcon
                                  style={{ color: "#4caf50" }}
                                  onClick={() => {
                                    handleEditClick(e);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon
                                  color="secondary"
                                  onClick={() => {
                                    handleDeleteClick(e.id);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr style={{ textAlign: "center", lineHeight: "2rem" }}>
                        No Data Found
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Stack>
          </>
        )}
        <Pagination
          count={Math.ceil(alleventInfo.length / postsPerPage)}
          page={currentPag}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          boundaryCount={1}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteMap(eventDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
