import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { bookNames, chapterCounts } from "../../../bibleMeta";
import { getChapter } from "../../../services/bibleService";
import {
  postChapterTitle,
  getChapterTitle,
} from "../../../services/verseService";
import { useParams, useHistory } from "react-router-dom";

function AdminChapterCreate() {
  let { bookNumber, chapter, version } = useParams();
  const history = useHistory();

  const [verseTitle, setverseTitle] = useState("");
  const [languageState, setlanguageState] = useState("English (NKJV)");
  const [chapterNo, setChapterNo] = useState(1);
  const [bookNo, setBookNo] = useState(localStorage.getItem("book_no") ?? 1);
  const [globalVersions, setGlobalVersions] = useState(
    localStorage.getItem("versions")
      ? localStorage.getItem("versions").split(",")
      : ["english_nkjv"]
  );
  const [bibleData, setBibleData] = useState({});
  const [error, setError] = useState("");

  const getChapterIndividually = async () => {
    if (bookNumber && chapter && version) {
      let re = await getChapterTitle(bookNumber, chapter, version).then(
        (res) => {
          let values = res.data.data;
          setBookNo(values.book_no);
          setChapterNo(values.chapter_no);
          setlanguageState(values.version);
          setverseTitle(values.title);
          handleLanguageChange(values.version, "edit");
        }
      );
    }
  };

  const renderBookOptions = () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];

    const options = names.map((name, index) => {
      return (
        <MenuItem key={index} value={Number(index) + 1}>
          {name}
        </MenuItem>
      );
    });

    return options;
  };

  const renderChapterOptions = () => {
    const options = [];
    for (let i = 1; i <= chapterCounts[Number(bookNo) - 1]; i++) {
      options.push(
        <MenuItem key={Number(i)} value={Number(i)}>
          {i}
        </MenuItem>
      );
    }
    return options;
  };

  const BackToChapterTitle = () => {
    history.push("/admin/verses");
  };

  const versetitleChange = (e) => {
    let errors = false;
    setverseTitle(e.target.value);
    if (verseTitle == "" || verseTitle == null) {
      setError("Verse title cannot be empty");
      errors = true;
    } else {
      setError("");
    }
    if (errors) {
      return;
    }
  };

  const changeBook = (no) => {
    localStorage.setItem("book_no", no);
    localStorage.setItem("chapter_no", 1);
    setBookNo(no);
    setChapterNo(1);
  };

  const handleBookChange = (e) => {
    changeBook(e.target.value);
  };

  const changeChapter = (no) => {
    if (no === "0") {
      return new Promise((resolve, reject) => {
        resolve("foo");
      });
    }
    localStorage.setItem("chapter_no", no);
    let promises = [];
    let newBibles = {};

    globalVersions.forEach((single) => {
      promises.push(
        getChapter(bookNo, no, [single]).then((response) => {
          newBibles[single] = response.data[single];
        })
      );
    });
    setChapterNo(no);
  };

  const handleChapterChange = (e) => {
    changeChapter(e.target.value);
  };

  const changeVersions = async (updated) => {
    let values1 = await localStorage.setItem("versions", updated);

    let promises = [];
    let newBibles = { ...bibleData };
    promises.push(
      getChapter(bookNo, chapterNo, [updated]).then((response) => {
        newBibles[updated] = response.data[updated];
      })
    );

    Promise.allSettled(promises).then(() => {
      setBibleData(newBibles);
      setGlobalVersions(
        localStorage.getItem("versions")
          ? localStorage.getItem("versions").split(",")
          : ["english_nkjv"]
      );
    });
    const names = bookNames[globalVersions[0]] ?? bookNames[updated];
    const options = names.map((name, index) => {
      return (
        <MenuItem key={index} value={Number(index) + 1}>
          {name}
        </MenuItem>
      );
    });
    return options;
  };

  const handleLanguageChange = (event, status) => {
    if (status == "edit") {
      setlanguageState(event);
      changeVersions(event);
    } else {
      setlanguageState(event.target.value);
      changeVersions(event.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = false;

    if (verseTitle == "" || verseTitle == null) {
      setError("Verse title cannot be empty");
      errors = true;
    } else {
      setError("");
    }
    if (errors) {
      return;
    }
    let verseTitles = {
      title: verseTitle,
    };
    postChapterTitle(bookNo, chapterNo, languageState, verseTitles)
      .then((res) => {
        history.push("/admin/verses");
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getChapterIndividually();
  }, []);

  return (
    <div>
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={BackToChapterTitle}
      >
        Back
      </Button>
      <div className="dictionary-main-page">
        <div className="article">
          <h1>
            {bookNumber && chapter && version ? "Edit" : "Create"} Chapter Title
          </h1>
        </div>
        <div className="paddingX">
          <div className="book-select-row">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Book
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={bookNo}
                onChange={handleBookChange}
                label="Book"
              >
                {renderBookOptions()}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Chapter
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={chapterNo}
                onChange={handleChapterChange}
                label="Chapter"
              >
                {renderChapterOptions()}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Language
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={languageState}
                onChange={handleLanguageChange}
                label="Language"
              >
                <MenuItem value={"english_nkjv"}>{"English (NKJV)"}</MenuItem>
                <MenuItem value={"malayalam"}>{"Malayalam (BSI OV)"}</MenuItem>
                <MenuItem value={"hindi"}>{"Hindi"}</MenuItem>
                <MenuItem value={"tamil"}>{"Tamil"}</MenuItem>
                <MenuItem value={"kannada"}>{"Kannada"}</MenuItem>
                <MenuItem value={"telugu"}>{"Telugu"}</MenuItem>
                <MenuItem value={"gujarati"}>{"Gujarati"}</MenuItem>
                <MenuItem value={"bengali"}>{"Bengali"}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="marginX">
            <label>
              Title
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>
            <TextField
              placeholder="Verse Title"
              type="textarea"
              variant="outlined"
              fullWidth
              id="title"
              onChange={versetitleChange}
              value={verseTitle}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              minRows={4}
            />
            <span className="error">{error}</span>
          </div>
          <div className="admin-chapter-title-submit-button-flex">
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "#3a65ff",
                color: "#fff",
              }}
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminChapterCreate;
