import { deleteSermon } from "../../services/sermonService";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import './SermonView.css';
import { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { Button } from "@material-ui/core";

export default function SermonView({
  user,
  sermon,
  sermons,
  setSermons,
  setEditingSermon,
  setActiveTab,
}) {
  const [deletingSermon, setDeletingSermon] = useState();
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const openDeleteForm = () => {
    setDeleteFormOpen(true);
  };
  const closeDeleteForm = () => {
    setDeleteFormOpen(false);
  };

  const handleDeleteClick = (sermon) => {
    setDeletingSermon(sermon);
    openDeleteForm();
  };

  const confirmDeletion = (sermon) => {
    deleteSermon(sermon.id).then((response) => {
      let newSermons = [];
      sermons.forEach((item) => {
        if (item.id !== sermon.id) {
          newSermons.push(item);
        }
      });
      setSermons(newSermons);
      closeDeleteForm();
      setActiveTab("index");
    });
  };

  const handleEditClick = () => {
    setEditingSermon(sermon);
    setActiveTab("edit");
  };

  return (
    <div>
      <div className="icon-btns">
        <Button
          startIcon={<ArrowBackIcon className="icon" />}
          onClick={() => {
            setActiveTab("index");
          }}
        >
          Back
        </Button>
        <div className="section">
          {user?.id === sermon.user.id && (
            <div className="options-buttons">
              <IconButton
                aria-label="Go back"
                onClick={() => {
                  handleDeleteClick(sermon);
                }}
              >
                <DeleteIcon style={{ color: "#f44336" }} />
              </IconButton>
              <IconButton aria-label="Edit" onClick={handleEditClick}>
                <EditIcon style={{ color: "#4caf50" }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <div className="sermon-view">
        <div className="view-header">
          <h1>{sermon.title}</h1>
        </div>
        <span className="sermon-meta">
          <b>Date:</b> {sermon.date}
        </span>
        <p>
          <b>Sermon</b>
          <span
            dangerouslySetInnerHTML={{ __html: sermon.sermon }}
            className="sermon-content-text"
          ></span>
        </p>
        <span className="sermon-meta">
          <b>Summary:</b> {sermon.summary}
        </span>
      </div>
      <ConfirmationDialog
        open={deleteFormOpen}
        onClose={closeDeleteForm}
        confirm={() => confirmDeletion(deletingSermon)}
        cancel={closeDeleteForm}
      />
    </div>
  );
}
