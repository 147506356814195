import React, { useState, useEffect } from "react";
import {
  createTorreys,
  editTorreys,
  getTorreyById,
} from "../../../services/torreyService";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function AdminTorreyCreate() {
  let { id } = useParams();
  let formDataInitialValue = {
    title: "",
    content: "",
    credits: "",
  };

  const [torreyBody, setTorreyBody] = useState(formDataInitialValue);
  const history = useHistory();
  const onTorreyChange = (e) => {
    let newTorreyData = { ...torreyBody };
    newTorreyData[e.target.id] = e.target.value;
    setTorreyBody(newTorreyData);
  };
  const [error, setError] = useState({
    title: false,
    content: false,
    msg: "",
  });

  const checkError = () => {
    setError({
      title: torreyBody.title === "" ? true : false,
      content: torreyBody.content === "" ? true : false,
      msg: "please fill valid data",
    });
  };

  const handleSubmit = () => {
    if (torreyBody.title === "" || torreyBody.content === "") {
      checkError();
    } else if (id) {
      editTorreys(id, torreyBody)
        .then((res) => {
          history.push(`/admin/view-torrey/${id}`);
        })
        .catch((error) => {});
    } else {
      createTorreys(torreyBody)
        .then((res) => {
          setTorreyBody(formDataInitialValue);
          history.push(`torreys`);
        })
        .catch((error) => {});
    }
  };

  const fetchTorreyById = () => {
    if (id) {
      getTorreyById(id)
        .then((res) => {
          setTorreyBody(res.data.data);
        })
        .catch((error) => {});
    }
  };
  const BackToPage = () => {
    history.push(`/admin/torreys`);
  };

  useEffect(() => {
    fetchTorreyById();
  }, []);

  return (
    <div>
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={BackToPage}
      >
        Back
      </Button>
      <div className="main-div">
        <h1 className="article"> {id ? "Edit" : "Create"} Torrey</h1>
        <div className="article-title">
          <div>
            <label>
              Torrey Title
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>
            <br />
            <input
              type="text"
              id="title"
              placeholder="Torrey Title"
              onChange={onTorreyChange}
              value={torreyBody.title}
              className="article-title-input"
            />
            {error.title ? (
              <div style={{ color: "red", textAlign: "left" }}>{error.msg}</div>
            ) : null}
          </div>
        </div>

        <div className="article-content">
          Torrey Content
          <span className="required" style={{ color: "#FF0000" }}>
            *
          </span>
          <textarea
            className="article-content-input"
            id="content"
            onChange={onTorreyChange}
            value={torreyBody.content}
          ></textarea>
          {error.content ? (
            <div style={{ color: "red", textAlign: "left" }}> {error.msg} </div>
          ) : null}
        </div>

        <div className="submit-btn">
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AdminTorreyCreate;
