import { CircularProgress } from '@material-ui/core'
import React from 'react'

function Loading() {
    return (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <CircularProgress/>
        </div>
    )
}

export default Loading
