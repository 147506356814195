import api from "./api";

export function addHighlight(bookNo, chapterNo, verseNo, version, color) {
    return api.post('/highlights', {
        book: bookNo,
        chapter: chapterNo,
        verse: verseNo,
        version: version,
        color_hex: color,
    }, {
        
    })
}

export function deleteHighlight(bookNo, chapterNo, verseNo) {
    return api.delete(`/highlights/b${bookNo}c${chapterNo}v${verseNo}`, {
        
    })
}