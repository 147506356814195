import { Button, InputAdornment, TextField } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { useHistory } from "react-router";
import React, { useState } from "react";
import { forgotPassword } from "../../services/authService";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ isError: false, errorText: "" });
  const [isloading, setLoading] = useState(false);

  // modal
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [popupTextMessage, setpopupTextMessage] = useState({
    message: "",
    isSuccess: false,
  });

  const history = useHistory();
  const gotoLogin = () => {
    history.push("/login");
  };

  const forgot_Password = () => {
    let isValid = validate(email, error);
    if (isValid) {
      setLoading(true);
      forgotPassword(email)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setpopupTextMessage({
              isSuccess: true,
              message: "Email send to the email id. Please check your email.",
            });
          } else {
            setOpen(true);
            setpopupTextMessage({ message: data.message, isSuccess: false });
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const validate = (email, errorState) => {
    let error = errorState;
    if (!email || email.length === 0) {
      error = { isError: true, errorText: "Please enter the mail" };
    } else if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
      error = { isError: true, errorText: "Please enter a valid email" };
    }
    setError(error);
    return !error.isError;
  };
  return (
    <div className="login">
      <form className="login-page">
        <div>
          <h2>Forgot Password</h2>
        </div>
        <p style={{ fontWeight: 400, textAlign: "center" }}>
          We'll email you instructions to reset the password.
        </p>
        <div className="input">
          <label>
            Email
            <span className="required" style={{ color: "#FF0000" }}>
              *
            </span>
          </label>

          <br />
          <TextField
            error={error.isError}
            helperText={error.errorText}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value.trim())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon style={{ color: "#BDBDBD" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Button
            type="button"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
            onClick={() => forgot_Password()}
            disabled={isloading}
          >
            {`${isloading ? "Loading" : "Reset Password"}`}
          </Button>
        </div>
        <div className="gotopage" style={{ justifyContent: "center" }}>
          <Button variant="outlined" onClick={() => gotoLogin()}>
            back to login page
          </Button>
        </div>
      </form>
      {/* <Popup /> */}
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <a
          className="close"
          onClick={closeModal}
          style={{ alignSelf: "end", fontSize: "30px" }}
        >
          &times;
        </a>
        <div style={{ margin: "0px 10px 10px 10px" }}>
          <div
            className="title"
            style={
              popupTextMessage.isSuccess ? { color: "green" } : { color: "red" }
            }
          >
            <span style={{ fontSize: "24px" }}>
              {`${popupTextMessage.isSuccess ? "Success" : "Error"}`}
            </span>
          </div>
          <div className="message" style={{ padding: "15px 5px" }}>
            {popupTextMessage.message}
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ForgotPassword;
