import { deleteArticles, articleAPI } from "../../../services/articleService";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Loading from "../../../components/loading/Loading";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import CustomPagination from "../../../components/pagination/customPagination";

function ArticleList() {
  const history = useHistory();
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(1);
  const [articleList, setArticleList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getArticleList = (page = initialPage, search = searchText) => {
    setLoading(true);
    articleAPI(page, search)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page, total },
        } = data;
        setinitialPage(current_page);
        setTotalPage(last_page);
        setArticleList(Data);
        setLoading(false);
      })
      .catch((e) => {
        setinitialPage(1);
        setTotalPage(1);
        setArticleList([]);
        setLoading(false);
      });
  };
  useEffect(() => {
    getArticleList();
  }, []);

  const viewArticleById = (id) => {
    history.push(`/admin/view-article/${id}`);
  };

  const [articleDeleteId, setarticleDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e, id) => {
    setOpen(true);
    setarticleDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onDeleteArticle = (id) => {
    deleteArticles(id)
      .then((res) => {
        getArticleList();
        handleClose(null);
      })
      .catch((error) => {});
  };

  return (
    <div className="main-bg">
      <div className="paddingX">
        <Grid container spacing={0} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={4}>
            <h2> ARTICLES </h2>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={() => {
                history.push("/admin/create-article");
              }}
            >
              CREATE Article
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="Search">
              <div className="Search-box">
                <input
                  className="Search-input"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                  onKeyDown={(e) => (e.key === "Enter" ? getArticleList() : {})}
                />
                {searchText ? (
                  <IconButton
                    aria-label="close"
                    color="secondary"
                    onClick={() => {
                      setsearchText("");
                      getArticleList(1, "");
                    }}
                    type="button"
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                <IconButton onClick={getArticleList} color="primary">
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="admin-card">
              <table>
                <tbody>
                  {articleList.length > 0 ? (
                    articleList.map((article, index) => (
                      <tr key={index}>
                        <td className="song-number">
                          {(initialPage - 1) * 10 + (index + 1)}
                        </td>
                        <td onClick={() => viewArticleById(article.id)}>
                          {article.title}
                        </td>
                        <td>
                          <Button
                            style={{ color: "#f44336" }}
                            onClick={(e) => handleClickOpen(e, article.id)}
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ textAlign: "center", lineHeight: "2rem" }}>
                      No Data Found
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="root">
              <CustomPagination
                page={initialPage}
                lastPage={TotalPage}
                onChangeFn={getArticleList}
              />
            </div>
          </div>
        )}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteArticle(articleDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ArticleList;
