import React, { useEffect, useState } from "react";
import { getChapterTitle } from "../../services/verseService";

function ChapterTitle({ book, chapter, vers }) {
  const [chapterTitle, setChapterTitle] = useState("");
  const getTitle = (book, chapter, vers) => {
    getChapterTitle(book, chapter, vers[0])
      .then((res) => {
        const response = res.data;
        setChapterTitle(response?.data?.title);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getTitle(book, chapter, vers);
  }, [vers, chapter, book]);
  return chapterTitle ? (
    <div className="chapter-title">{chapterTitle}</div>
  ) : null;
}

export default ChapterTitle;
