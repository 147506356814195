import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Grid, TextField } from "@material-ui/core";
import dateFormat from "dateformat";
import { Tooltip } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  createEvent,
  editEvent,
  getEventsOftwodate,
} from "../../services/eventsService";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

function CreateEvent() {
  let tooltipInstance = null;
  const history = useHistory();
  const { event } = useParams();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [eventInfo, seteventInfo] = useState([]);
  const [startDateerror, setstartDateerror] = useState("");
  const [formTimeFrom, setFormTimeFrom] = useState("");
  const [totDateerror, settoDateerror] = useState("");
  const [formTimeTo, setFormTimeTo] = useState("");
  const [fromError, setFromError] = useState("");
  const [dateerrorFocus, setdateerrorFocus] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [toError, setToError] = useState("");
  const [formPlace, setFormPlace] = useState("");
  const [placeError, setPlaceError] = useState("");
  const [formDetails, setFormDetails] = useState("");
  const [events, setEvents] = useState([]);
  const InputDate = ({ onChange, value, isSecure, id, onClick }) => (
    <TextField
      label={"From"}
      placeholder={"--/--/----"}
      variant="outlined"
      onChange={onChange}
      value={value}
      isSecure={isSecure}
      id={id}
      onClick={onClick}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
  const InputendDate = ({ onChange, value, isSecure, id, onClick }) => (
    <TextField
      label={"To"}
      placeholder={"--/--/----"}
      variant="outlined"
      onChange={onChange}
      value={value}
      isSecure={isSecure}
      id={id}
      onClick={onClick}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
    />
  );

  const onchangeEvents = (e, status, val) => {
    if (e.target == null) {
      var date = new Date(startDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      let values = [mnth, day, date.getFullYear()].join("-");

      var dates = new Date(e),
        mnth = ("0" + (dates.getMonth() + 1)).slice(-2),
        day = ("0" + dates.getDate()).slice(-2);
      let valuess = [mnth, day, dates.getFullYear()].join("-");

      var date1 = values;
      var date2 = valuess;
      var time1 = moment(date1).format("YYYY-MM-DD");
      var time2 = moment(date2).format("YYYY-MM-DD");
      var d1 = Date.parse(time1);
      var d2 = Date.parse(time2);
      if (d1 > d2) {
        setdateerrorFocus("End date should be greater than starting date.");
      }

      if (d1 <= d2) {
        setdateerrorFocus("");
      }
    }

    if (status == "sdTime") {
      setFormTimeFrom(val);
    }
    if (status == "endTime") {
      setFormTimeTo(val);
    }
  };

  // calendar functions
  const handleMouseEnter = (info) => {
    if (info.event.extendedProps.title) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event.extendedProps.title,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      });

      tooltipInstance.show();
    }
  };
  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };
  function eventRender(info) {
    var tooltip = new Tooltip(info.el, {
      title: info.event.extendedProps.title,
      placement: "top",
      trigger: "click",
      container: "body",
    });
  }

  // used in calendar section
  const openAddForm = (e) => {
    if (e != undefined) {
      var date = new Date(e);
      setStartDate(date);
    } else {
      setendDate(false);
      setStartDate(false);
    }
    setAddFormOpen(true);
    seteventInfo([]);
  };

  // calendar section
  const eventView = (e, val) => {
    const timeString = e._def.extendedProps.to;
    const timeStrings = e._def.extendedProps.from;
    const timetoString12hr = new Date(
      "1970-01-01T" + timeString + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
    const timefromString12hr = new Date(
      "1970-01-01T" + timeStrings + "Z"
    ).toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
    eventInfo.push({
      id: e._def.publicId,
      start_date: dateFormat(e._instance.range.start, "dd-mmmm-yyyy"),
      end_date: dateFormat(e._instance.range.end, "dd-mmmm-yyyy"),
      date: dateFormat(e._instance.range.start, "dd-mmmm-yyyy"),
      enddate: dateFormat(e._instance.range.end, "dd-mmmm-yyyy"),
      details: e._def.extendedProps.details,
      place: e._def.extendedProps.place,
      timeFrom: timefromString12hr,
      timeTo: timetoString12hr,
      from: timeStrings,
      to: timeString,
      title: e.title,
    });

    seteventInfo(eventInfo);
  };

  const handleSaveClick = () => {
    let errors = false;
    if (formTimeFrom === "") {
      setFromError("Pick a from time");
      errors = true;
    } else {
      setFromError("");
    }
    if (formTimeTo === "") {
      setToError("Pick a to time");
      errors = true;
    } else {
      setToError("");
    }
    if (formTitle === "") {
      setTitleError("Title cannot be empty");
      errors = true;
    } else {
      setTitleError("");
    }
    if (formPlace === "") {
      setPlaceError("Place cannot be empty");
      errors = true;
    } else {
      setPlaceError("");
    }
    if (startDate == "" || startDate == null) {
      setstartDateerror("From date cannot be empty");
      errors = true;
    } else {
      setstartDateerror("");
    }
    if (endDate == "" || endDate == null) {
      settoDateerror("To date cannot be empty");
      errors = true;
    } else {
      settoDateerror("");
    }
    if (errors) {
      return;
    }

    let date = `${startDate.getFullYear()}-${
      Number(startDate.getMonth()) + 1 < 10
        ? "0" + (Number(startDate.getMonth()) + 1)
        : Number(startDate.getMonth()) + 1
    }-${
      Number(startDate.getDate()) < 10
        ? "0" + Number(startDate.getDate())
        : Number(startDate.getDate())
    }`;
    let enddates = `${endDate.getFullYear()}-${
      Number(endDate.getMonth()) + 1 < 10
        ? "0" + (Number(endDate.getMonth()) + 1)
        : Number(endDate.getMonth()) + 1
    }-${
      Number(endDate.getDate()) < 10
        ? "0" + Number(endDate.getDate())
        : Number(endDate.getDate())
    }`;
    if (event) {
      const object = JSON.parse(event);
      editEvent(
        object.id,
        dateFormat(startDate, "yyyy-mm-dd"),
        dateFormat(endDate, "yyyy-mm-dd"),
        formTimeFrom,
        formTimeTo,
        formTitle,
        formPlace,
        formDetails
      ).then((response) => {
        history.push(`/calendar`);
      });
    } else {
      createEvent(
        date,
        enddates,
        formTimeFrom,
        formTimeTo,
        formTitle,
        formPlace,
        formDetails
      ).then((response) => {
        console.log(response);
        setAddFormOpen(false);
      });
    }
  };

  const pageLoad = async () => {
    await getEventsOftwodate("1900-01-01", "9022-06-06").then((response) => {
      let allDetails = response.data.data;
      let event = [...events];
      let alleve = [...allDetails];
      alleve.map((er, ir) => {
        event.push({
          id: er.id,
          title: er.title,
          start: er.date + "T" + er.from,
          end: er.end_date + "T" + er.to,
          from: er.from,
          place: er.place,
          to: er.to,
          date: er.date,
          enddate: er.end_date,
          details: er.details,
          user_id: er.user_id,
        });
      });
      setEvents(event);
    });
  };

  useEffect(() => {
    if (event) {
      const object = JSON.parse(event);
      const startDate = new Date(object.start_date);
      const endDate = new Date(object.end_date);
      setStartDate(startDate);
      setendDate(endDate);
      setFormTimeFrom(object.from);
      setFormTimeTo(object.to);
      setFormTitle(object.title);
      setFormPlace(object.place);
      setFormDetails(object.details);
      setAddFormOpen(true);
    }
    pageLoad();
  }, []);
  const viewCalendarList = () => {
    history.push("/calendar");
  };
  return (
    <div className="paddingX calendar-page">
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={viewCalendarList}
      >
        Back
      </Button>
      <div>
        <h1 style={{ margin: 30 }}>{event ? "Edit" : "Add"} Event</h1>
      </div>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} lg={5}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              start: "new",
              end: "today,title,prev,next",
            }}
            eventRender={(e) => eventRender(e)}
            eventMouseEnter={(e) => handleMouseEnter(e)}
            eventMouseLeave={(e) => handleMouseLeave(e)}
            customButtons={{
              new: {
                text: "Add new Event",
                click: () => {
                  openAddForm();
                },
              },
            }}
            editable
            eventResizableFromStart
            selectable
            dayMaxEvents={true}
            aspectRatio={6}
            height={473}
            displayEventTime={true}
            events={events}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              meridiem: false,
            }}
            eventColor="#3a65ff"
            nowIndicator
            dateClick={(e) => openAddForm(e.dateStr)}
            eventClick={(e) => eventView(e.event, e.dateStr)}
          />
        </Grid>
        {addFormOpen === true ? (
          <Grid item xs={12} md={6} lg={7}>
            <div className="create-event-input-flex-column">
              <label>
                Event start date
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>
              <div className="create-event-input-flex-row">
                <div>
                  <DatePicker
                    fullWidth
                    value={startDate}
                    dateFormat="MMMM-dd-yyyy"
                    customInput={<InputDate />}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      onchangeEvents(date, "stDate");
                    }}
                  />
                  <span className="error">{startDateerror}</span>
                </div>
                <div style={{ paddingLeft: "10px" }}></div>
                <div>
                  <TextField
                    label="Start time"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={formTimeFrom}
                    onChange={(e) => {
                      onchangeEvents(e, "sdTime", e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span className="error">{fromError}</span>
                </div>
              </div>
            </div>
            <div className="create-event-input-flex-column">
              <label>
                Event end date{" "}
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>
              <div className="create-event-input-flex-row">
                <div>
                  <DatePicker
                    value={endDate}
                    dateFormat="MMMM-dd-yyyy"
                    customInput={<InputendDate />}
                    selected={endDate}
                    onChange={(date) => {
                      setendDate(date);
                      onchangeEvents(date, "stDate");
                    }}
                    fullWidth
                  />
                  <span className="error">{totDateerror}</span>
                </div>

                <div style={{ paddingLeft: "10px" }}></div>
                <div>
                  <TextField
                    label="End time"
                    type="time"
                    variant="outlined"
                    value={formTimeTo}
                    fullWidth
                    onChange={(e) => {
                      onchangeEvents(e, "endTime", e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span className="error">{toError}</span>
                </div>
              </div>
            </div>
            {dateerrorFocus != "" ? (
              <div style={{ padding: "10px" }}>
                <span className="error">{dateerrorFocus}</span>
              </div>
            ) : (
              ""
            )}
            <div className="input">
              <label>
                Title
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <TextField
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Title"
                value={formTitle}
                onChange={(e) => {
                  setFormTitle(e.target.value);
                  onchangeEvents(e, "");
                }}
              />
              <span className="error">{titleError}</span>
            </div>
            <div className="input">
              <label>
                Place
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <TextField
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Place"
                value={formPlace}
                onChange={(e) => {
                  setFormPlace(e.target.value);
                  onchangeEvents(e, "");
                }}
              />
              <span className="error">{placeError}</span>
            </div>
            <div className="input">
              <label>
                Details
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <TextField
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Details"
                value={formDetails}
                onChange={(e) => {
                  setFormDetails(e.target.value);
                  onchangeEvents(e, "");
                }}
                multiline
                rows={4}
              />
              <span className="error">{titleError}</span>
            </div>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              fullWidth
              onClick={handleSaveClick}
            >
              {event ? "Update" : "Save"} Event
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}

export default CreateEvent;
