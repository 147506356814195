import api from "./api";

export function getSongById(id) {
  return api.get(`/song/${id}`);
}

export function createSong(body) {
  return api.post(`/song`, body, {});
}

export function editSong(id, body) {
  return api.post(`/song/${id}`, body, {});
}

export function deleteSong(id) {
  return api.delete(`/song/${id}`);
}

export function getSongCategories() {
  return api.get(`/song-categories`);
}

export function getSongContentType() {
  return api.get(`/song-content-type`);
}

export const songAPI = (page = 1, searchText = "", category, quantity = 10) =>
  api.get(`/songs?page=${page}&search=${searchText}&category=${category}&quantity=${quantity}`);
