import { useState } from "react";
import { createSermon } from "../../services/sermonService";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CommonEditor from "../CommonEditor/CommonEditor";
// import './SermonCreate.css'

export default function SermonCreate({
  setActiveTab,
  sermons,
  setSermons,
  user,
}) {
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [sermonText, setSermonText] = useState("");
  const [sermonError, setSermonError] = useState("");

  const handleSaveClick = () => {
    let errors = false;
    if (title === "") {
      setTitleError("Title cannot be empty.");
      errors = true;
    }
    if (location === "") {
      setLocationError("Location cannot be empty.");
      errors = true;
    }
    if (selectedDate === "") {
      setDateError("Please select a date.");
      errors = true;
    }
    if (summary === "") {
      setSummaryError("Summary cannot be empty.");
      errors = true;
    }
    if (sermonText === "") {
      setSermonError("Sermon cannot be empty");
      errors = true;
    }

    if (errors) {
      return;
    }

    createSermon(title, location, selectedDate, summary, sermonText).then(
      (response) => {
        let temp = response.data;
        temp.user = user;
        let newSermons = [temp, ...sermons];
        setSermons(newSermons);
        setActiveTab("index");
      }
    );
  };

  return (
    <div>
      <div className="create-header">
        <Button
          onClick={() => {
            setActiveTab("index");
          }}
          startIcon={<ArrowBackIcon className="icon" />}
        >
          Back
        </Button>
      </div>
      <h1>Create sermon</h1>
      <div className="sermon-create">
        <div className="create-body">
          <div className="row">
            <div className="item">
              <label>
                Title
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{titleError}</span>
              <input
                className="input"
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="item">
              <label>
                Location
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{locationError}</span>
              <input
                className="input"
                type="text"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </div>
            <div className="item">
              <label>
                Date
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{dateError}</span>
              <input
                className="input"
                type="date"
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
                value={selectedDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="item">
              <label>
                Summary
                <span className="required" style={{ color: "#FF0000" }}>
                  *
                </span>
              </label>

              <span className="error">{summaryError}</span>
              <textarea
                value={summary}
                onChange={(e) => {
                  setSummary(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="item">
              <CommonEditor
                value={sermonText}
                onChangeText={setSermonText}
                className="note-add-form"
              />
              <span className="error">{sermonError}</span>
            </div>
          </div>
          <div className="row float-right">
            <Button
              variant="contained"
              style={{ backgroundColor: "#3a65ff", color: "#fff" }}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
