import { bookNames } from "../../bibleMeta";
import { deleteNote } from "../../services/noteService";
// import "./NoteListBox.css";
import { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconButton, MenuItem, Select } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

export default function NoteListBox({
  noteVerseNo,
  setNoteVerseNo,
  chapterNotes,
  setChapterNotes,
  setCurrentEditNote,
  toggles,
  handleClose,
  handleToggle,
}) {
  const [deletingNote, setDeletingNote] = useState();
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const openDeleteForm = () => {
    setDeleteFormOpen(true);
  };
  const closeDeleteForm = () => {
    setDeleteFormOpen(false);
  };

  const handleDeleteClick = (note) => {
    setDeletingNote(note);
    openDeleteForm();
  };

  const confirmDeletion = (note) => {
    deleteNote(note.id).then((response) => {
      const newChapterNotes = { ...chapterNotes };
      let newVerseNotes = [];
      newChapterNotes[note.verse_no].forEach((item) => {
        if (item.id !== note.id) {
          newVerseNotes.push(item);
        }
      });
      newChapterNotes[note.verse_no] = newVerseNotes;
      setChapterNotes(newChapterNotes);
      closeDeleteForm();
    });
  };

  const handleEditClick = (note) => {
    setCurrentEditNote(note);
    closeBox();
    handleToggle("noteEditBox");
  };

  const renderVerseNotes = (verse) => {
    const elements = [];
    if (noteVerseNo === "0") {
      const versesWithNotes = Object.keys(chapterNotes);

      versesWithNotes.forEach((verseNo) => {
        chapterNotes[verseNo].forEach((item, index) => {
          elements.push(
            <Accordion className="note-list-item" key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {bookNames["english_nkjv"][Number(item.book_no) - 1]}
                {item.chapter_no} : {item.verse_no}
              </AccordionSummary>
              <AccordionDetails className="note-accordion-content">
                <div className="flex-between">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.note }}
                    style={{ display: "block" }}
                  ></div>
                  <div className="flex-between">
                    <IconButton
                      onClick={() => {
                        handleDeleteClick(item);
                      }}
                      title="Delete Note"
                    >
                      <DeleteIcon style={{ color: "#f44336" }} />
                    </IconButton>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => {
                        handleEditClick(item);
                      }}
                    >
                      <EditIcon style={{ color: "#4caf50" }} />
                    </IconButton>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        });
      });
    } else {
      chapterNotes[noteVerseNo]?.forEach((item, index) => {
        elements.push(
          <Accordion className="note-list-item" key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {bookNames["english_nkjv"][Number(item.book_no) - 1]}
              {item.chapter_no} : {item.verse_no}
            </AccordionSummary>
            <AccordionDetails className="note-accordion-content">
              <div className="flex-between">
                <div
                  dangerouslySetInnerHTML={{ __html: item.note }}
                  style={{ display: "block" }}
                ></div>
                <div style={{ float: "right" }}>
                  <IconButton
                    onClick={() => {
                      handleDeleteClick(item);
                    }}
                    title="Delete Note"
                  >
                    <DeleteIcon style={{ color: "#f44336" }} />
                  </IconButton>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      handleEditClick(item);
                    }}
                  >
                    <EditIcon style={{ color: "#4caf50" }} />
                  </IconButton>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      });
    }
    return elements;
  };

  const renderVerseOptions = () => {
    const options = [];

    options.push(
      <MenuItem value="0" key="0">
        All Verses
      </MenuItem>
    );

    Object.keys(chapterNotes).forEach((item) => {
      if (chapterNotes[item].length !== 0) {
        options.push(
          <option key={item} value={item}>
            {item}
          </option>
        );
      }
    });

    return options;
  };

  const closeBox = () => {
    handleClose("notes");
  };

  return toggles.notes ? (
    <div className={`box note-list-box aside`}>
      <div className="box-header">
        <h2>Notes List</h2>
        <span className="close-button" onClick={closeBox}>
          &times;
        </span>
      </div>
      <div className="box-content">
        <div>
          <label>Show Notes from</label>&nbsp;
          <Select
            variant="outlined"
            onChange={(event) => {
              setNoteVerseNo(event.target.value);
            }}
            value={noteVerseNo}
          >
            {renderVerseOptions()}
          </Select>
        </div>
        <div>{renderVerseNotes()}</div>
      </div>
      <ConfirmationDialog
        open={deleteFormOpen}
        onClose={closeDeleteForm}
        confirm={() => confirmDeletion(deletingNote)}
        cancel={closeDeleteForm}
      />
    </div>
  ) : null;
}
