import { useState } from "react";
import { bookNames } from "../../bibleMeta";
import { addNote } from "../../services/noteService";
import CommonEditor from "../CommonEditor/CommonEditor";
// import "./NoteAddBox.css";

export default function NoteAddBox({
  oldBookNo,
  oldChapterNo,
  noteVerseNo,
  chapterNotes,
  setChapterNotes,
  toggles,
  handleToggle,
  handleClose,
}) {
  const [noteError, setNoteError] = useState("");
  const [noteText, setNoteText] = useState("");

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    if (noteText === "") {
      setNoteError("Note cannot be empty");
      return;
    }

    addNote(oldBookNo, oldChapterNo, noteVerseNo, "english_nkjv", noteText)
      .then((response) => {
        const newNote = response.data;
        const newChapterNotes = { ...chapterNotes };
        if (newChapterNotes[newNote.verse_no]) {
          newChapterNotes[newNote.verse_no].push(newNote);
        } else {
          newChapterNotes[newNote.verse_no] = [newNote];
        }
        setChapterNotes(newChapterNotes);
        setNoteText("");
        setNoteError("");
        handleClose("noteAddBox");
        handleToggle("notes");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("token_type");
          setNoteError("You need to be logged in to Add Notes.");
        }
      });
  };

  const closeBox = () => {
    handleClose("noteAddBox");
  };

  return toggles.noteAddBox ? (
    <div className={`box note-add-box aside`}>
      <div className="box-header">
        <h2>Add Note</h2>
        <span className="close-button" onClick={closeBox}>
          &times;
        </span>
      </div>
      <div className="box-content">
        <div className="notes">
          Add Note to {bookNames["english_nkjv"][Number(oldBookNo) - 1]}
          {oldChapterNo}: {noteVerseNo}
        </div>
        <form className="note-add-form" onSubmit={handleAddFormSubmit}>
          <CommonEditor value={noteText} onChangeText={setNoteText} />
          <div className="note-error">{noteError}</div>
          <input type="submit" value="Save Note" className="button" />
        </form>
      </div>
    </div>
  ) : null;
}
