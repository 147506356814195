import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import {
  deleteUser,
  editUsernew,
  userAPI,
} from "../../../services/userService";
import Loading from "../../../components/loading/Loading";
import CustomPagination from "../../../components/pagination/customPagination";

function UserManagement() {
  const [loading, setLoading] = useState();
  const [userList, setUserList] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(1);
  const [getusrType, setusrType] = useState("");
  const [userEditId, setUserEditId] = useState("");
  const [pswdEdit, setPswdEdit] = useState("");

  let updateAdmin = {
    password: "",
    user_type: "",
  };
  const [userDeleteId, setUserDeleteId] = useState("");
  const [adminBody, setadminBody] = useState(updateAdmin);
  const [anchor, setAnchor] = useState(null);
  const [defaultType, setdefaultType] = useState("");
  const openPopover = (e, id) => {
    setUserDeleteId(id);
    setAnchor(e.currentTarget);
  };
  const openEdit = (e, id, userType = "") => {
    setUserEditId(id);
    setdefaultType(userType === 1 ? "Admin" : "User");
    setEditAnchor(e.currentTarget);
  };
  const onDeleteUser = () => {
    deleteUser(userDeleteId)
      .then((res) => {
        getUserList();
        setAnchor(null);
      })
      .catch((error) => {});
  };

  const getUserList = (page = initialPage, search = searchText) => {
    setLoading(true);
    userAPI(page, search)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page, total },
        } = data;
        setinitialPage(current_page);
        setTotalPage(last_page);
        setUserList(Data);
        setLoading(false);
      })
      .catch((e) => {
        setinitialPage(1);
        setTotalPage(1);
        setUserList([]);
        setLoading(false);
      });
  };

  const onChangeHandler = (event) => {
    if (event.target.value == "User") {
      setusrType(0);
    }
    if (event.target.value == "Admin") {
      setusrType(1);
    }
  };

  const PswdChanger = (e) => {
    let newPswd = { ...adminBody };
    newPswd = e.target.value;
    setPswdEdit(newPswd);
  };
  const [editanchor, setEditAnchor] = useState(null);

  const onEditUserType = async (e, id, usr_type, pswd) => {
    let adminBodys = { ...adminBody };
    adminBodys.password = pswd;
    adminBodys.user_type = usr_type;
    setadminBody(adminBodys);

    let getData = await editUsernew(userEditId, adminBodys)
      .then((res) => {
        getUserList();
        setEditAnchor(null);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div className="main-bg">
      <div className="paddingX">
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <h2>USERS</h2>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="Search">
              <div className="Search-box">
                <input
                  className="Search-input"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                  onKeyDown={(e) => (e.key === "Enter" ? getUserList() : {})}
                />

                {searchText ? (
                  <IconButton
                    aria-label="close"
                    color="secondary"
                    onClick={() => {
                      setsearchText("");
                      getUserList(1, "");
                    }}
                    type="button"
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  ""
                )}
                <IconButton
                  aria-label="close"
                  onClick={getUserList}
                  type="button"
                  color="primary"
                >
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <div className="admin-card">
            <table>
              <thead>
                <tr>
                  <th>sl.no</th>
                  <th>Name</th>
                  <th>Mail ID</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.length > 0
                  ? userList?.map((userBody, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                          {(initialPage - 1) * 10 + (index + 1)}
                        </td>
                        <td> {userBody.name} </td>
                        <td> {userBody.email} </td>
                        <td> {userBody.user_type === 1 ? "Admin" : "User"} </td>

                        <td>
                          <div style={{ display: "flex" }}>
                            <Button
                              variant="outlined"
                              style={{ color: "#4caf50", margin: "0 10px" }}
                              onClick={(e) =>
                                openEdit(e, userBody.id, userBody.user_type)
                              }
                            >
                              EDIT
                            </Button>
                            <Popover
                              open={Boolean(editanchor)}
                              anchorEl={editanchor}
                              onClose={() => setEditAnchor(null)}
                            >
                              <div className="popover">
                                <div className="popover-title">
                                  Edit User Type
                                </div>
                                <div>
                                  <Select
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={defaultType}
                                    style={{ margin: "10px 0" }}
                                    id="userType"
                                    onChange={(event) => onChangeHandler(event)}
                                  >
                                    <MenuItem value="Select">
                                      --Select--
                                    </MenuItem>
                                    <MenuItem value="User">User</MenuItem>
                                    <MenuItem value="Admin">Admin</MenuItem>
                                  </Select>
                                </div>
                                <div style={{ margin: "10px 0" }}>
                                  <label>Edit Password</label>
                                  <TextField
                                    onChange={PswdChanger}
                                    fullWidth
                                    variant="outlined"
                                  />
                                </div>
                                <div className="flex-between">
                                  <Button
                                    variant="outlined"
                                    style={{ margin: "0 10px" }}
                                    onClick={() => setEditAnchor(null)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#3a65ff",
                                      color: "#fff",
                                    }}
                                    onClick={(e) =>
                                      onEditUserType(
                                        e,
                                        userBody.id,
                                        getusrType,
                                        pswdEdit
                                      )
                                    }
                                  >
                                    Confirm
                                  </Button>
                                </div>
                              </div>
                            </Popover>
                            <Button
                              style={{ color: "#f44336" }}
                              onClick={(e) => openPopover(e, userBody.id)}
                              variant="outlined"
                            >
                              Delete
                            </Button>
                            <Popover
                              open={Boolean(anchor)}
                              anchorEl={anchor}
                              onClose={() => setAnchor(null)}
                            >
                              <div className="popover">
                                <div className="popover-title">Delete User</div>
                                <div>
                                  <p>Are you sure to delete User ?</p>
                                </div>
                                <div className="flex-between">
                                  <Button
                                    variant="outlined"
                                    style={{ margin: "0 10px" }}
                                    onClick={() => setAnchor(null)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#3a65ff",
                                      color: "#fff",
                                    }}
                                    onClick={() => onDeleteUser()}
                                  >
                                    Confirm
                                  </Button>
                                </div>
                              </div>
                            </Popover>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        )}
        <div className="root">
          <CustomPagination lastPage={TotalPage} onChangeFn={getUserList} />
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
