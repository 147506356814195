import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import Loading from "../../../components/loading/Loading";
import { useParams, useHistory } from "react-router-dom";
import {
  deleteArticles,
  getArticleById,
} from "../../../services/articleService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

function AdminArticleView(props) {
  let { id } = useParams();
  const history = useHistory();
  const [specificArticle, setSpecificArticle] = useState({});

  const getSpecificArticle = (article) => {
    getArticleById(id)
      .then((res) => {
        setSpecificArticle(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSpecificArticle();
  }, []);

  const BackToAdminArticleLists = () => {
    history.push(`/admin/articles/`);
  };

  const editArticles = () => {
    history.push(`/admin/edit-article/${id}`);
  };

  const onDeleteArticle = () => {
    deleteArticles(id)
      .then((res) => {
        history.push(`/admin/articles/`);
      })
      .catch((error) => {});
  };
  const [loading, setLoading] = useState(true);

  const [articleDeleteId, setArticleDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e, id) => {
    setOpen(true);
    setArticleDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="icon-btns">
        <Button
          startIcon={<ArrowBackIcon className="icon" />}
          onClick={BackToAdminArticleLists}
        >
          Back
        </Button>
        <div>
          <Button
            onClick={editArticles}
            variant="outlined"
            style={{ color: "#4caf50" }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => handleClickOpen(e)}
            style={{ color: "#f44336" }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="song">
          <div className="song-sheet">
            <div className="song-title">
              {specificArticle ? <h3> {specificArticle.title}</h3> : null}
            </div>
            <div className="admin-article-sheet">
              <div>
                <p
                  dangerouslySetInnerHTML={{ __html: specificArticle?.content }}
                ></p>
                <p className="user-credit">{specificArticle?.credits}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteArticle(articleDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminArticleView;
