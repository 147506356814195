import React from "react";
import UserArticleList from "./UserArticle/UserArticleList";

function Article({ toggles, handleClose }) {
  const closeArticleBox = () => {
    handleClose('article');
  };

  return (toggles.article ? (
    <div className={`box article-box aside`}>
      <div className="box-header">
        <h2>Article</h2>
        <span className="close-button" onClick={closeArticleBox}>
          &times;
        </span>
      </div>
      <div className="user-article">
        <UserArticleList />
      </div>
    </div>
  ) : null);
}

export default Article;
