import { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { getUser, register } from "../../services/authService";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

export default function RegisterPage({ setUserIsLoading, setUser }) {
  const [formError, setFormError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordRe, setPasswordRe] = useState("");
  const [passwordReError, setPasswordReError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirm = () => setShowConfirm((open) => !open);

  const history = useHistory();
  const gotoLogin = () => {
    history.push("/login");
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validating form data
    let errors = false;
    if (name === "") {
      setNameError("Please enter your name.");
      errors = true;
    } else {
      setNameError("");
    }
    if (email === "") {
      setEmailError("Enter your email.");
      errors = true;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Enter a password.");
      errors = true;
    } else {
      setPasswordError("");
    }
    if (passwordRe === "") {
      setPasswordReError("Retype your password.");
      errors = true;
    } else {
      setPasswordReError("");
    }
    if (password !== passwordRe) {
      setPasswordError("Passwords do not match.");
      setPasswordReError("Passwords do not match.");
      errors = true;
    }

    if (errors) return;

    // Calling API
    register({
      name: name,
      email: email,
      password: password,
    })
      .then((response) => {
        // Registration success
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("token_type", response.data.token_type);
        getUser()
          .then((response) => {
            // User data recived since access tken was valid.
            setUserIsLoading(false);
            setUser(response.data);
          })
          .catch((error) => {
            setUserIsLoading(false);
            if (error.response?.status === 401) {
              // 401 means unauthenticated.
              // That is, the access token is invalid or expired.
              // So we will remove the token from local storage.
              localStorage.removeItem("access_token");
              localStorage.removeItem("token_type");
            }
          });
        history.push("/");
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        setFormError("Registration failed.");
        setNameError(errors?.name ?? "");
        setEmailError(errors?.email ?? "");
      });
  };

  if (localStorage.getItem("access_token")) {
    return <Redirect to="/" />;
  }

  return (
    <div className="register">
      <form onSubmit={handleSubmit} className="register-page">
        <p>{formError}</p>
        <div>
          <h2>Register</h2>
        </div>
        <div className="input">
          <label>
            Name
            <span className="required" style={{ color: "#FF0000" }}>
              *
            </span>
          </label>

          <br />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <i>{nameError}</i>
        </div>
        <div className="input">
          <label>Email</label>

          <br />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <i>{emailError}</i>
        </div>
        <div className="input">
          <label>Password</label>

          <br />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <i>{passwordError}</i>
        </div>
        <div className="input">
          <label>Confirm password</label>

          <br />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            type={showConfirm ? "text" : "password"}
            placeholder="Retype password"
            value={passwordRe}
            onChange={(e) => setPasswordRe(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirm}
                    edge="end"
                  >
                    {showConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <i>{passwordReError}</i>
        </div>
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
          >
            Register
          </Button>
        </div>
        <div className="gotopage">
          Already Registered
          <Button variant="outlined" onClick={gotoLogin}>
            login
          </Button>
        </div>
      </form>
    </div>
  );
}
