import api from "./api";

export function addNote(bookNo, chapterNo, verseNo, version, note) {
    return api.post('/notes', {
        book: bookNo,
        chapter: chapterNo,
        verse: verseNo,
        version: version,
        note: note,
    }, {
        
    })
}

export function editNote(id, note) {
    return api.patch(`/notes/${id}`, {
        note: note,
    }, {
       
    })
}

export function getChapterNotes(bookNo, chapterNo) {
    return api.get(`/notes/book-${bookNo}/chapter-${chapterNo}`, {
       
    })
}

export function deleteNote(noteId) {
    return api.delete(`/notes/${noteId}`, {
        
    })
}