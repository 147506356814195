import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams, useHistory } from "react-router-dom";
import { deleteMaps } from "../../../services/mapService";
import { getMapsById } from "../../../services/mapService";
import Loading from "../../../components/loading/Loading";

function AdminMapView() {
  let { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [specificMap, setSpecificMap] = useState({});
  const [mapDeleteDialogOpen, setMapDeleteDialogOpen] = useState(false);

  const getSpecificMap = () => {
    getMapsById(id)
      .then((res) => {
        let data = res.data.data;

        setSpecificMap(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getSpecificMap();
  }, []);
  const editMap = () => {
    history.push(`/admin/edit-map/${id}`);
  };

  const mapDeleteClick = () => {
    setMapDeleteDialogOpen(true);
  };

  const onDeleteMap = () => {
    deleteMaps(id)
      .then((res) => {
        setMapDeleteDialogOpen(false);
        history.push(`/admin/maps/`);
      })
      .catch((error) => {});
  };

  const mapDeleteDialogClose = () => {
    setMapDeleteDialogOpen(false);
  };

  const BackToAdminMapLists = () => {
    history.push(`/admin/maps/`);
  };
  return (
    <div>
      <div className="icon-btns">
        <Button
          startIcon={<ArrowBackIcon className="icon" />}
          onClick={BackToAdminMapLists}
        >
          Back
        </Button>
        <div>
          <Button
            onClick={editMap}
            variant="outlined"
            style={{ color: "#4caf50" }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={mapDeleteClick}
            style={{ color: "#f44336" }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="song">
          <div className="song-sheet">
            <div className="song-title">
              <h3>{specificMap.title}</h3>
            </div>
            <div className="admin-article-sheet">
              <div className="admin-map-view-image-flex-center">
                <img
                  src={specificMap.img_url}
                  alt={specificMap.title}
                  height="600px"
                  className="admin-map-image-preview"
                />
              </div>
              <p className="text-center">{specificMap.description}</p>
            </div>
          </div>
        </div>
      )}
      <Dialog open={mapDeleteDialogOpen} onClose={mapDeleteDialogClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteMap}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={mapDeleteDialogClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminMapView;
