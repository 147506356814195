import api from "./api";

export function  getUserList(query){
    return  api.get(`/users${query}`)
}

export function  getUserType(){
    return  api.get( `/user-types`)
}

export function  getUserById(id){
    return  api.get( `/user/{id}`)
}

export function  editUser(id){
    return  api.post( `/user/{id}/edit`)
}

export function  editUserPassword(id){
    return  api.post( `/user/${id}/change-password`)
}
export function  editUsernew(id,obj){
    return  api.post( `/user/${id}/edit`,obj)
}

export function deleteUser(id) {
    return  api.delete(`/user/${id}`)
}
export const userAPI = (page = 1, searchText = "", quantity = 10) =>
  api.get(`/users?page=${page}&search=${searchText}&quantity=${quantity}`);
