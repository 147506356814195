import { useEffect, useState } from "react";
import { getDictionaryWords } from "../../services/dictionaryService";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Button, MenuItem, Select } from "@material-ui/core";
// import './DictionaryBox.css'

export default function DictionaryBox({ toggles, handleClose }) {
  const [tab, setTab] = useState("index");
  const [selectedLetter, setSelectedLetter] = useState("a");
  const [words, setWords] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermError, setSearchTermError] = useState("");

  const closeDictionaryBox = () => {
    handleClose('dictionary');
  };
  const alphabets = [
    "-Select-",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  const renderLetterOptions = () => {
    return alphabets.map((item) => {
      return (
        <MenuItem key={item} value={item}>
          {item.toUpperCase()}
        </MenuItem>
      );
    });
  };

  const handleLetterChange = (event) => {
    setSelectedLetter(event.target.value);
  };

  useEffect(() => {
    getDictionaryWords(selectedLetter).then((response) => {
      setWords(response.data);
    });
  }, [selectedLetter]);

  const handleSearchClick = () => {
    if (searchTerm === "") {
      setSearchTermError("Search term cannot be empty");
      return;
    }
    setSearchTermError("");
    getDictionaryWords(searchTerm).then((response) => {
      setSearchResults(response.data);
    });
  };

  const renderWords = () => {
    return words.map((item) => {
      return (
        <Accordion key={item.id}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            {item.word}
          </AccordionSummary>
          <AccordionDetails>{item.meaning}</AccordionDetails>
        </Accordion>
      );
    });
  };

  const renderSearchResults = () => {
    return searchResults.map((item) => {
      return (
        <Accordion key={item.id}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            {item.word}
          </AccordionSummary>
          <AccordionDetails>{item.meaning}</AccordionDetails>
        </Accordion>
      );
    });
  };

  return (toggles.dictionary ? (
    <div className={`box dictionary-box aside`}>
      <div className="box-header">
        <h2>Dictionary</h2>
        <span className="close-button" onClick={closeDictionaryBox}>
          &times;
        </span>
      </div>
      <div className="dictionary-tab-buttons">
        <div
          className={
            tab === "index"
              ? "dictionary-tab-button active"
              : "dictionary-tab-button"
          }
          onClick={() => {
            setTab("index");
          }}
        >
          Index
        </div>
        <div
          className={
            tab === "search"
              ? "dictionary-tab-button active"
              : "dictionary-tab-button"
          }
          onClick={() => {
            setTab("search");
          }}
        >
          Search
        </div>
      </div>
      {tab === "index" && (
        <div className="dictionary-tab dictionary-index-tab">
          <div>
            <label>Letter</label>
            <Select
              variant="outlined"
              onChange={handleLetterChange}
              value={selectedLetter}
            >
              {renderLetterOptions()}
            </Select>
          </div>
          <div className="words">{renderWords()}</div>
        </div>
      )}
      {tab === "search" && (
        <div className="dictionary-tab dictionary-search-tab">
          <div className="dictionary-search-form">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => (e.key === "Enter" ? handleSearchClick() : {})}
            />
            <Button
              onClick={handleSearchClick}
              variant="contained"
              style={{
                backgroundColor: "#3a65ff",
                color: "#fff",
                borderRadius: 0,
              }}
            >
              Search
            </Button>
          </div>
          <div>{searchTermError}</div>
          <div className="search-results">{renderSearchResults()}</div>
        </div>
      )}
    </div>
  ) : null);
}
