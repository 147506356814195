import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";

// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import "react-quill/dist/quill.snow.css";
import { uploadImage } from "../../services/imageService";

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

class CommonEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }

  modules = {
    // #3 Add "image" to the toolbar
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    // # 4 Add module and upload function
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("image", file);

          uploadImage(formData).then((response) => {
            resolve(response.data.url);
          });
        });
      },
    },
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot", // #5 Optimal if using custom formats
  ];

  render() {
    return (
      <ReactQuill
        theme="snow"
        className={this.props.classname || ""}
        modules={this.modules}
        formats={this.formats}
        value={this.props.value}
        onChange={this.props.onChangeText}
      />
    );
  }
}

export default CommonEditor;
