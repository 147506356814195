import { songAPI } from "../../services/songService";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, IconButton } from "@material-ui/core";
import Loading from "../../components/loading/Loading";
import { getSongCategories } from "../../services/songService";
import CustomPagination from "../../components/pagination/customPagination";

function UserSongsList() {
  const history = useHistory();
  const location = useLocation();
  const [songList, setSongList] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [initialPage, setinitialPage] = useState(location.state?.page ?? 1);
  const [categoryId, setCategoryId] = useState(0);

  const getSongList = (
    page = initialPage,
    search = searchText,
    category = categoryId
  ) => {
    setLoading(true);
    songAPI(page, search, category)
      .then(({ data }) => {
        const {
          data: { data: Data, current_page, last_page, total },
        } = data;
        setinitialPage(current_page);
        setTotalPage(last_page);
        setSongList(Data);
        setLoading(false);
      })
      .catch((e) => {
        setinitialPage(1);
        setTotalPage(1);
        setSongList([]);
        setLoading(false);
      });
  };
  useEffect(() => {
    getSongList();
  }, [categoryId]);
  useEffect(() => {
    songCategory();
  }, []);

  const viewSongById = (id) => {
    history.push({
      pathname: `/songs/view-song/${id}`,
      state: { page: initialPage },
    });
  };
  const [loading, setLoading] = useState(true);

  const [songCategories, setSongCategories] = useState([]);
  const songCategory = () => {
    getSongCategories()
      .then((res) => {
        setSongCategories(res.data.data);
      })
      .catch((error) => {});
  };

  const onSelectSongCategory = (e) => {
    setCategoryId(e.target.value);
    setinitialPage(1);
  };
  return (
    <div className="main-bg">
      <div className="dictionary-main-page ">
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <h2> Songs </h2>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="Search-box">
              <div className="songs-input-box">
                <select id="category" onChange={onSelectSongCategory}>
                  <option value={0}>All Songs</option>

                  {songCategories.length > 0
                    ? songCategories?.map((song) => (
                        <option key={song.id} value={song.id}>
                          {song.text}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <input
                className="Search-input"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setsearchText(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? getSongList() : {})}
              />

              {searchText ? (
                <IconButton
                  aria-label="close"
                  color="secondary"
                  onClick={() => {
                    setsearchText("");
                    getSongList(1, "");
                  }}
                  type="button"
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                ""
              )}
              <IconButton
                aria-label="close"
                onClick={getSongList}
                type="button"
                color="primary"
              >
                <SearchIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <div className="admin-card">
            <table>
              <tbody>
                {songList.length > 0 ? (
                  songList?.map(
                    (song, index) => (
                      <tr key={song.id}>
                        <td className="song-number">
                          {(initialPage - 1) * 10 + (index + 1)}
                        </td>
                        <td onClick={() => viewSongById(song.id)}>
                          {song.title}
                        </td>
                        <td>{song.id}</td>
                      </tr>
                    )
                    // )
                  )
                ) : (
                  <span className="no-songs">No songs found</span>
                )}
              </tbody>
            </table>
          </div>
        )}
        <div className="root">
          <CustomPagination
            page={initialPage}
            lastPage={TotalPage}
            onChangeFn={getSongList}
          />
        </div>
      </div>
    </div>
  );
}

export default UserSongsList;
