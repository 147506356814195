import api from "./api";

export function getChapter(bookNo, chapterNo, versions = ["english_nkjv"]) {
    return api.get(`/bible/book-${bookNo}/chapter-${chapterNo}?versions=${versions.sort().join()}`)
}

export function searchBible(version, from, to, term) {
    return api.get("/search", {
        params: {
            version: version,
            from: from,
            to: to,
            term: encodeURI(term)
        }
    })
}

export function getCommentary(bookNo, chapterNo) {
    return api.get(`/commentary/embed/book-${bookNo}/chapter-${chapterNo}`)
}

export function getTorreys() {
    return  api.get(`/torreys/embed`)
}