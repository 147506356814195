import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import Loading from "../../../components/loading/Loading";
import { useParams, useHistory } from "react-router-dom";
import { deleteTorreys, getTorreyById } from "../../../services/torreyService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

function AdminTorreyView(props) {
  let { id } = useParams();
  const history = useHistory();
  const [specificTorrey, setSpecificTorrey] = useState({});

  const getSpecificTorrey = (article) => {
    getTorreyById(id)
      .then((res) => {
        setSpecificTorrey(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSpecificTorrey();
  }, []);

  const BackToAdminTorreyLists = () => {
    history.push(`/admin/torreys/`);
  };

  const editTorreys = () => {
    history.push(`/admin/edit-torrey/${id}`);
  };

  const onDeleteTorrey = () => {
    deleteTorreys(id)
      .then((res) => {
        history.push(`/admin/torreys/`);
      })
      .catch((error) => {});
  };
  const [loading, setLoading] = useState(true);

  const [torreyDeleteId, setTorreyDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = (e, id) => {
    setOpen(true);
    setTorreyDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="icon-btns">
        <Button
          startIcon={<ArrowBackIcon className="icon" />}
          onClick={BackToAdminTorreyLists}
        >
          Back
        </Button>
        <div>
          <Button
            onClick={editTorreys}
            variant="outlined"
            style={{ color: "#4caf50" }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => handleClickOpen(e)}
            style={{ color: "#f44336" }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="song">
          <div className="song-sheet">
            <div className="song-title">
              {specificTorrey ? <h3> {specificTorrey.title}</h3> : null}
            </div>
            <div className="admin-article-sheet">
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: specificTorrey?.content.replaceAll("\n", "<br/>"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <div>Are you sure you want to delete?</div>
          <div className="confirmation-buttons">
            <Button
              onClick={() => onDeleteTorrey(torreyDeleteId)}
              variant="outlined"
              style={{ color: "#f44336", margin: "0 5px" }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              style={{ color: "#4caf50" }}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminTorreyView;
