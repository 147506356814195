import { Pagination } from "@material-ui/lab";
import React from "react";

const CustomPagination = ({ page, lastPage, onChangeFn = () => {} }) => {
  const handleChange = (event, value) => {
    onChangeFn(value);
  };

  return (
    <Pagination
      page={page}
      boundaryCount={1}
      count={lastPage}
      onChange={handleChange}
      variant="outlined"
      shape="rounded"
    />
  );
};

export default CustomPagination;
