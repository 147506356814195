import React, { useState, useEffect } from "react";
import { Button, Container } from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  createMaps,
  editMaps,
  getMapsById,
} from "../../../services/mapService";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    marginTop: "8px",
  },
  input: {
    display: "none",
  },
}));

function AdminMapCreate() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const [mapTitle, setMapTitle] = useState("");
  const [mapTitleError, setMapTitleError] = useState("");
  const [mapDescription, setMapDescription] = useState("");
  const [mapDescriptionError, setMapDescriptionError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [mapImage, setMapImage] = useState("");
  const [selectedImageError, setSelectedImageError] = useState("");

  useEffect(() => {
    getSpecificMap();
  }, []);

  const getSpecificMap = () => {
    if (id) {
      getMapsById(id)
        .then((res) => {
          let data = res.data.data;
          setMapTitle(data.title);
          setMapDescription(data.description);
          setMapImage(data.img_url);
        })
        .catch((error) => {});
    }
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = () => {
    let error = false;

    if (mapTitle === "") {
      setMapTitleError("please fill valid data");
      error = true;
    } else {
      setMapTitleError("");
    }
    if (mapDescription === "") {
      setMapDescriptionError("please fill valid data");
      error = true;
    } else {
      setMapDescriptionError("");
    }
    if (selectedImage === null) {
      setSelectedImageError("please select the image");
      error = true;
    } else {
      setSelectedImageError("");
    }
    if (error) return;

    let formData = new FormData();

    formData.append("title", mapTitle);
    formData.append("image", selectedImage);
    formData.append("description", mapDescription);

    if (id) {
      // formData.append("id", id);
      editMaps(id, formData)
        .then((res) => {
          history.push(`/admin/maps`);
        })
        .catch((error) => {});
    } else {
      createMaps(formData)
        .then((res) => {
          history.push(`maps`);
        })
        .catch((error) => {});
    }
  };

  const BackToPage = () => {
    history.push(`/admin/maps`);
  };
  return (
    <div>
      <Button
        startIcon={<ArrowBackIcon className="icon" />}
        onClick={BackToPage}
      >
        Back
      </Button>
      <div className="main-div">
        <h1 className="map">{id ? "Edit" : "Create"} Map</h1>
        <div className="map-title">
          <div>
            <label>
              Map Title
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>
            <br />
            <input
              type="text"
              id="title"
              placeholder="Map Title"
              value={mapTitle}
              onChange={(e) => {
                setMapTitle(e.target.value);
              }}
              className="map-title-input"
            ></input>
            {mapTitleError ? (
              <div style={{ color: "red", textAlign: "left" }}>
                {mapTitleError}
              </div>
            ) : null}
          </div>

          <div>
            <label>
              Map Description
              <span className="required" style={{ color: "#FF0000" }}>
                *
              </span>
            </label>
            <br />
            <input
              type="text"
              id="title"
              placeholder="Map Description"
              value={mapDescription}
              onChange={(e) => {
                setMapDescription(e.target.value);
              }}
              className="map-title-input"
            ></input>
            {mapDescriptionError ? (
              <div style={{ color: "red", textAlign: "left" }}>
                {mapDescriptionError}
              </div>
            ) : null}
          </div>
        </div>

        <div className="article-content">
          <label>
            Upload Map
            <span className="required" style={{ color: "#FF0000" }}>
              *
            </span>
          </label>
          <Container className={classes.container} style={{ padding: 0 }}>
            <input
              accept="image/*"
              className={classes.input}
              id="image-upload"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="image-upload">
              <Button
                variant="contained"
                component="span"
                style={{ backgroundColor: "#3a65ff", color: "#fff" }}
                startIcon={<CloudUploadIcon />}
              >
                Select Image
              </Button>
            </label>
            {selectedImageError ? (
              <div style={{ color: "red", textAlign: "left" }}>
                {selectedImageError}
              </div>
            ) : null}
          </Container>
        </div>
        <div className="submit-btn">
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#3a65ff", color: "#fff" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
        {selectedImage && (
          <div className="map-image-preview-content">
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Image"
              width="50%"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminMapCreate;
